<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-02-27 09:04:55
 * @LastEditors: pj
 * @LastEditTime: 2023-05-08 11:15:22
-->
<template>
    <ul class="qs-sidebar-submenu">
        <li v-show="!item.meta.isShow" :class="
        currentRoute == item.path
          ? 'qs-sidebar-submenu-item qs-sidebar-ac clearfix'
          : 'qs-sidebar-submenu-item clearfix'
      " v-for="(item, index) in children" :key="index" @click="handleJump(item)">
            <div class="qs-sidebar-submenu-text">
                {{ item.meta.title }} {{ item.meta.activeMenu }}
            </div>
            <div v-if="item.meta.num" :class=" item.meta.countIsshow ? 'qs-sidebar-submenu-num' : 'displayNone'">{{ item.meta.num }}</div>
            <div v-else :class="item.meta.countIsshow ? 'qs-sidebar-submenu-num' : 'displayNone'">0</div>
        </li>
    </ul>
</template>

<script>
export default {
    props: ['children'],
    data() {
        return {}
    },
    computed: {
        currentRoute() {
            return this.$route.meta.activeMenu
                ? this.$route.meta.activeMenu
                : this.$route.path
        },
    },
    methods: {
        handleJump(item) {
            this.$router.push(item.path)
        },
    },
}
</script>

<style lang="scss" scoped>
// 二级
.qs-sidebar-submenu {
    white-space: nowrap;
    .qs-sidebar-submenu-item {
        height: 50px;
        line-height: 50px;
        padding: 0 24px;
        cursor: pointer;
        position: relative;
        &:hover {
            background: #eaf1ff;
            border-radius: 0;
            .qs-sidebar-submenu-text {
                color: #105fff;
                &::after {
                    content: '';
                    display: block;
                    width: 3px;
                    height: 100%;
                    background: #105fff;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
            .qs-sidebar-submenu-num {
                color: #105fff;
            }
        }
        .qs-sidebar-submenu-text {
            display: inline-block;
            box-sizing: border-box;
            padding-left: 16px;
            color: #333333;
            font-size: 14px;
            max-width: 162px;
            overflow: hidden;
        }
        .qs-sidebar-submenu-num {
            display: inline-block;
            color: #808eaa;
            font-size: 14px;
            padding-right: 10px;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
        &.qs-sidebar-ac {
            background: #eaf1ff;
            border-radius: 0;
            .qs-sidebar-submenu-text {
                color: #105fff;
                &::after {
                    content: '';
                    display: block;
                    width: 3px;
                    height: 100%;
                    background: #105fff;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
            .qs-sidebar-submenu-num {
                color: #105fff;
            }
        }
    }
}
</style>

import { render, staticRenderFns } from "./changePassward.vue?vue&type=template&id=88f4c352&scoped=true&"
import script from "./changePassward.vue?vue&type=script&lang=js&"
export * from "./changePassward.vue?vue&type=script&lang=js&"
import style0 from "./changePassward.vue?vue&type=style&index=0&id=88f4c352&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88f4c352",
  null
  
)

export default component.exports
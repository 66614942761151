import layout from '@/layout/index.vue'
const talent = {
	path: '/talent',
	component: layout,
	name: 'talent',
	redirect: '/talent/classify',
	meta: {
		title: '人才',
		isSideBar: true
	},
	children: [{
			path: '/talent/classify',
			component: () => import('@/views/talent/classify/index.vue'),
			name: 'talentClassify',
			redirect: '/talent/classify/allTalent',
			meta: {
				title: '人才分类'
			},
			children: [{
					path: '/talent/classify/allTalent',
					component: () => import('@/views/talent/classify/allTalent/index.vue'),
					name: 'talentClassifyAllTalent',
					redirect: '/talent/classify/allTalent/list',
					meta: {
						title: '所有人才'
					},
					children: [{
							path: '/talent/classify/allTalent/list',
							component: () => import('@/views/talent/classify/allTalent/list.vue'),
							name: 'talentClassifyAllTalentList',
							meta: {
								title: '所有人才',
								isShow: true,
								activeMenu: '/talent/classify/allTalent'
							},
						}, {
							path: '/talent/classify/allTalent/add',
							component: () => import('@/views/talent/classify/allTalent/add.vue'),
							name: 'talentClassifyAllTalentAdd',
							meta: {
								title: '新增人才',
								isShow: true,
								activeMenu: '/talent/classify/allTalent'
							},
						},
						{
							path: '/talent/classify/allTalent/edit',
							component: () => import('@/views/talent/classify/allTalent/edit.vue'),
							name: 'talentClassifyAllTalentEdit',
							meta: {
								title: '编辑人才',
								isShow: true,
								activeMenu: '/talent/classify/allTalent'
							},
						},
					]
				},
				{
					path: '/talent/classify/enterTalent',
					component: () => import('@/views/talent/classify/enterTalent/index.vue'),
					name: 'talentClassifyEnterTalent',
					redirect: '/talent/classify/enterTalent/list',
					meta: {
						title: '我入库的人才'
					},
					children: [{
						path: '/talent/classify/enterTalent/list',
						component: () => import('@/views/talent/classify/enterTalent/list.vue'),
						name: 'talentClassifyenterTalentList',
						meta: {
							title: '我入库的人才',
							isShow: true,
							activeMenu: '/talent/classify/enterTalent'
						},
					}]
				},
				{
					path: '/talent/classify/browseTalent',
					component: () => import('@/views/talent/classify/browseTalent/index.vue'),
					name: 'talentClassifyBrowseTalent',
					redirect: '/talent/classify/browseTalent/list',
					meta: {
						title: '最近浏览的人才'
					},
					children: [{
						path: '/talent/classify/browseTalent/list',
						component: () => import('@/views/talent/classify/browseTalent/list.vue'),
						name: 'talentClassifybrowseTalentList',
						meta: {
							title: '最近浏览的人才',
							isShow: true,
							activeMenu: '/talent/classify/browseTalent'
						},
					}]
				},
				{
					path: '/talent/classify/attentionTalent',
					component: () => import('@/views/talent/classify/attentionTalent/index.vue'),
					name: 'talentClassifyAttentionTalent',
					redirect: '/talent/classify/attentionTalent/list',
					meta: {
						title: '关注人才'
					},
					children: [{
						path: '/talent/classify/attentionTalent/list',
						component: () => import('@/views/talent/classify/attentionTalent/list.vue'),
						name: 'talentClassifyattentionTalentList',
						meta: {
							title: '关注人才',
							isShow: true,
							activeMenu: '/talent/classify/attentionTalent'
						},
					}]
				},
				{
					path: '/talent/classify/remarksTalent',
					component: () => import('@/views/talent/classify/remarksTalent/index.vue'),
					name: 'talentClassifyRemarksTalent',
					redirect: '/talent/classify/remarksTalent/list',
					meta: {
						title: '备注人才'
					},
					children: [{
						path: '/talent/classify/remarksTalent/list',
						component: () => import('@/views/talent/classify/remarksTalent/list.vue'),
						name: 'talentClassifyremarksTalentList',
						meta: {
							title: '备注人才',
							isShow: true,
							activeMenu: '/talent/classify/remarksTalent'
						},
					}]
				},
			]
		},
		{
			path: '/talent/favorites',
			component: () => import('@/views/talent/favorites/index.vue'),
			name: 'talentFavorites',
			meta: {
				title: '人才收藏夹'
			},
			children: [{
				path: '/talent/favorites/talentPool/:type',
				component: () => import('@/views/talent/favorites/talentPool/index.vue'),
				name: 'talentFavoritesTalentPool',
				meta: {
					title: ''
				},
			}]
		},
		{
			path: '/talent/recycle',
			component: () => import('@/views/talent/recycle/index.vue'),
			name: 'talentRecycle',
			meta: {
				title: '回收站'
			},
			children: [{
				path: '/talent/recycle/recycleList',
				component: () => import('@/views/talent/recycle/recycleList/index.vue'),
				name: 'talentRecycleTalent',
				redirect: '/talent/recycle/recycleList/list',
				meta: {
					title: '人才回收站'
				},
				children: [{
					path: '/talent/recycle/recycleList/list',
					component: () => import('@/views/talent/recycle/recycleList/list.vue'),
					name: 'talentRecycleTalentList',
					meta: {
						title: '人才回收站',
						isShow: true,
						activeMenu: '/talent/recycle/recycleList'
					},
				}]
			}, ]
		}
	]
}
export default talent

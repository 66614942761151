<template>
    <div class="qs-layout-wrapper">
        <HeaderMenu :parentMenu="parentMenu"></HeaderMenu>
        <div :class="
        isShowZoom ? 'qs-layout-main' : 'qs-layout-main qs-layout-main-ac'
      ">
            <Sidebar :parentMenu="parentMenu"></Sidebar>
            <AppMain></AppMain>
        </div>
    </div>
</template>

<script>
import AppMain from '@/layout/components/AppMain.vue'
import HeaderMenu from '@/layout/components/headerMenu.vue'
import Sidebar from '@/layout/components/sidebar.vue'
import { mapState, mapMutations } from 'vuex'
export default {
    components: {
        AppMain,
        HeaderMenu,
        Sidebar,
    },
    computed: {
        ...mapState({
            isSideBar: (state) => state.layout.isSideBar,
            isZoom: (state) => state.layout.isZoom,
        }),
        isShowZoom() {
            if (this.isSideBar) {
                return this.isZoom
            } else {
                return false
            }
        },
        parentMenu() {
            const matched = this.$route.matched
            const matchedModulePath =
                matched[0].path == '' ? '/' : matched[0].path
            this.SET_ROOTMENUID(matched[0].meta.menu_id)
            return matchedModulePath
        },
    },
    methods: {
        ...mapMutations({
            SET_ISZOOM: 'layout/SET_ISZOOM',
            SET_ROOTMENUID: 'layout/SET_ROOTMENUID',
        }),
    },
}
</script>

<style lang="scss" scoped>
.qs-layout-wrapper {
    width: 100%;
    height: 100%;
    .qs-layout-main {
        height: calc(100% - 70px);
        // margin-left: 280px;
        position: relative;
        transition: all 0.5s;
        &.qs-layout-main-ac {
            margin-left: 0;
        }
    }
}
</style>
<template>
	<div class="qs-account-box">
		<div class="qs-account-title">账号设置</div>
		<div class="qs-account-content">
			<div class="qs-account-userInfo">
				<div class="qs-account-userInfo-title">用户信息</div>
				<div class="qs-account-userInfo-list">
					<div class="qs-account-userInfo-item">
						<div class="qs-account-userInfo-label">姓名：</div>
						<div class="qs-account-userInfo-value">
							<div v-if="editName" class="qs-editMobile">
								<el-input class="qs-name" type="text" v-model="userInfo.user_name" />
								<span class="qs-save-name" @click="toDo('submitName')"></span>
								<span class="qs-cancel-name" @click="toDo('canceleditName')"></span>
							</div>
							<div class="qs-user-name-edit" v-else @click="toDo('editName')">{{ userInfo.user_name }}
							</div>
						</div>
					</div>
					<div class="qs-account-userInfo-item">
						<div class="qs-account-userInfo-label">所在公司：</div>
						<div class="qs-account-userInfo-value">{{ userInfo.company_name }}</div>
					</div>
					<div class="qs-account-userInfo-item">
						<div class="qs-account-userInfo-label">上次登录时间：</div>
						<div class="qs-account-userInfo-value">{{ userInfo.last_login_time }}</div>
					</div>
				</div>
			</div>
			<div class="qs-account-userInfo">
				<div class="qs-account-userInfo-title">安全设置</div>
				<div class="qs-account-userInfo-list">
					<div class="qs-account-userInfo-item">
						<div class="qs-account-userInfo-label">手机：</div>
						<div class="qs-account-userInfo-value">
							<div class="qs-change-mobile">
								{{ userInfo.mobile }}
								<span class="qs-edit-txt" @click="toDo('editMobile')">更换</span>
							</div>
						</div>
					</div>
					<!-- <div class="qs-account-userInfo-item">
                        <div class="qs-account-userInfo-label">微信：</div>
                        <div class="qs-account-userInfo-value">
                            <span class="qs-wechat-bind" v-if="userInfo.wechat_bind == 1">已绑定</span>
                            <span v-else>未绑定</span>
                            <span class="qs-wechat-bind-btn" v-if="userInfo.wechat_bind == 0" @click="toDo('wechatBind')">绑定</span>
                            <span class="qs-wechat-bind-btn" v-else @click="toDo('cancelWechat')">解绑</span>
                        </div>
                    </div> -->
					<div class="qs-account-userInfo-item">
						<div class="qs-account-userInfo-label">登录密码：</div>
						<div class="qs-account-userInfo-value">
							<div class="qs-change-mobile">
								******
								<span class="qs-edit-txt" @click="toDo('editPassward')">更换</span>
							</div>
							<!-- <el-input class="qs-mobile" type="password" :show-password="true" v-model="userInfo.passward" /> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 修改手机号 -->
		<changeMobile ref="refMobile" @changeMobileFun="changeMobileFun" @updateEditMobile="updateEditMobile"
			:mobile="userInfo.mobile"></changeMobile>
		<!-- 修改密码 -->
		<changePassward ref="refPassward" @changePasswardFun="changePasswardFun" @updateEditPasward="updateEditPasward">
		</changePassward>
		<!-- 绑定微信 -->
		<bindWechat ref="refBindWechat" @updateUserinfo="initData"></bindWechat>
	</div>
</template>

<script>
	import {
		getAccountInfo
	} from '@/api/user/index'
	import {
		changeAccountInfo
	} from '@/api/public/index'
	import {
		unbindWechat
	} from '@/api/user/index'
	import {
		dateFormatter
	} from '@/utils/index'
	import {
		mapMutations,
		mapActions
	} from 'vuex'
	import {
		setUserInfo
	} from '@/utils/auth'
	import changeMobile from '@/components/changeMobile.vue'
	import changePassward from '@/components/changePassward.vue'
	import bindWechat from '@/components/bindWechat.vue'
	import md5 from 'js-md5'
	export default {
		components: {
			changeMobile,
			changePassward,
			bindWechat,
		},
		data() {
			return {
				userInfo: {
					user_name: '',
					company_name: '',
					mobile: '',
					wechatBind: 1,
					last_login_time: '',
					passward: '',
					new_passward: '',
					rel_new_passward: '',
				},
				copyUserinfo: {},
				editName: false,
				editMobile: false,
				editPassward: false,
				submitForm: {},
			}
		},
		mounted() {
			this.initData()
		},
		methods: {
			...mapMutations({
				SET_USERINFO: 'user/SET_USERINFO',
				SET_WECHAT_BIND: 'user/SET_WECHAT_BIND',
				SET_USERNAME: 'user/SET_USERNAME',
			}),
			...mapActions({
				getContact: 'common/getContact',
			}),
			updateEditMobile(data) {
				this.editMobile = false
			},
			updateEditPasward(data) {
				this.editPassward = false
			},
			toDo(oprate) {
				switch (oprate) {
					case 'editName':
						this.editName = true
						break
					case 'editMobile':
						this.editMobile = true
						this.$refs.refMobile.handleOpen()
						this.$refs.refMobile.form.mobile = this.userInfo.mobile
						this.$refs.refMobile.form.verify_code = ''
						break
					case 'editPassward':
						this.editPassward = true
						this.$refs.refPassward.handleOpen()
						break
					case 'canceleditName':
						this.userInfo.user_name = this.copyUserinfo.user_name
						this.editName = false
						break
					case 'canceleditMobile':
						this.userInfo.mobile = this.copyUserinfo.mobile
						this.editMobile = false
						break
					case 'submitMobile':
						break
					case 'submitName':
						this.submitForm.user_name = this.userInfo.user_name
						this.changeInfo()
						break
					case 'closeDrawer':
						this.$emit('closeDrawer', false)
						break
					case 'wechatBind':
						this.$refs.refBindWechat.getWechatQrcode()
						break
					case 'cancelWechat':
						this.cancelWechatQrcode()
						break
					default:
						break
				}
			},
			cancelWechatQrcode() {
				this.$confirm('确定解除微信绑定吗?', '提示', {
						confirmButtonText: '确定',
						confirmButtonClass: 'confirmbtn', //自定义class
						cancelButtonText: '取消',
						type: 'warning',
					})
					.then(() => {
						unbindWechat()
							.then((res) => {
								if (res.code == '0000') {
									this.$message.success(res.msg)
									this.userInfo.wechat_bind = 0
									this.SET_USERINFO(this.userInfo)
									this.initData()
								}
							})
							.catch(() => {})
					})
					.catch(() => {})
			},
			initData() {
				getAccountInfo()
					.then((res) => {
						if (res.code == '0000') {
							this.userInfo = res.data
							this.userInfo.last_login_time = dateFormatter(
								this.userInfo.last_login_time * 1000
							)
							this.copyUserinfo = {
								...this.userInfo
							}
							let wechat_bind = res.data.wechat_bind
							this.SET_WECHAT_BIND(wechat_bind)
						}
					})
					.catch(() => {})
			},
			changeInfo() {
				let params = this.submitForm
				changeAccountInfo(params)
					.then((res) => {
						if (res.code == '0000') {
							this.$message.success(res.msg)
							this.editName = false
							if (this.editMobile) {
								this.$refs.refMobile.handleClose()
								this.editMobile = false
							}
							if (this.editPassward) {
								this.$refs.refPassward.handleClose()
								this.editPassward = false
							}
							this.initData()
							var userInfo = {
								is_creator: this.userInfo.is_creator,
								user_id: this.userInfo.user_id,
								user_name: this.userInfo.user_name,
							}
							setUserInfo(JSON.stringify(userInfo))
							this.SET_USERINFO(userInfo)
							this.SET_USERNAME(this.userInfo.user_name)
							this.getContact()
						}
					})
					.catch(() => {})
			},
			changeMobileFun(data) {
				this.submitForm = data
				this.changeInfo()
			},
			changePasswardFun(data) {
				this.submitForm = {
					cur_password: md5(data.cur_password),
					new_password: md5(data.new_password),
					confirm_password: md5(data.confirm_password),
				}
				this.changeInfo()
			},
		},
	}
</script>

<style lang="scss" scoped>
	.qs-account-box {
		.qs-account-title {
			color: #333333;
			font-size: 16px;
			padding: 22px 24px;
			border-bottom: 1px solid #e3eef6;
			box-sizing: border-box;
		}

		.qs-account-content {
			box-sizing: border-box;
			padding: 24px;

			.qs-account-userInfo {
				width: 100%;
				background: #ffffff;
				border-radius: 0px 0px 6px 6px;
				margin-bottom: 15px;
				box-shadow: 0px 0px 13px 0px rgb(245 245 245);
				margin-left: 10px;

				.qs-account-userInfo-title {
					font-size: 16px;
					color: #333;
					box-sizing: border-box;
					padding: 17px 22px;
					border-bottom: 1px solid #f3f8ff;
					background: #f9f9f9;
				}

				.qs-account-userInfo-list {
					box-sizing: border-box;
					padding: 24px 70px;

					.qs-account-userInfo-item {
						border-bottom: 1px dashed #e3eef6;
						display: flex;
						align-items: center;
						justify-content: left;

						&.qs-account-btn {
							box-sizing: border-box;
							padding: 20px 0 20px 150px;
						}

						&.qs-no-border {
							border: none;
						}

						.qs-account-userInfo-label {
							font-size: 14px;
							color: #666;
							text-align: right;
							margin-right: 5px;
							padding: 20px 0;
						}

						.qs-account-userInfo-value {
							font-size: 14px;
							color: #333;
							display: flex;
							align-items: center;
							justify-content: left;

							.qs-editMobile {
								display: flex;
								align-items: center;
								justify-content: left;
							}

							.qs-wechat-bind {
								display: inline-block;
								width: 75px;
								height: 25px;
								text-align: center;
								line-height: 25px;
								background: #f3f8ff;
								border-radius: 6px;
								color: #6874f2;
								font-size: 14px;
							}

							.qs-save-name {
								display: inline-block;
								width: 42px;
								height: 32px;
								border-radius: 6px;
								background: #f4f8ff url('@/assets/images/talent/sure-icon.png') center center no-repeat;
								background-size: 18px 12px;
								margin: 0 15px;
								cursor: pointer;
							}

							.qs-cancel-name {
								display: inline-block;
								width: 42px;
								height: 32px;
								border-radius: 6px;
								background: #f4f8ff url('@/assets/images/talent/cancel-icon.png') center center no-repeat;
								background-size: 12px 12px;
								cursor: pointer;
							}

							.qs-user-name-edit {
								background: url('@/assets/images/editTagIcon.png') right center no-repeat;
								background-size: 14px 14px;
								padding-right: 25px;
								cursor: pointer;
							}

							.qs-change-mobile {
								.qs-edit-txt {
									color: #6874f2;
									cursor: pointer;
									margin-left: 5px;
								}
							}

							.qs-wechat-bind-btn {
								color: #6874f2;
								cursor: pointer;
								margin-left: 10px;
							}
						}
					}
				}
			}
		}
	}
</style>

<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-04-11 15:42:56
 * @LastEditors: pj
 * @LastEditTime: 2023-05-04 11:46:25
-->
<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
    <div :class="
      isShowZoom ? 'qs-sidebar-wrapper' : 'qs-sidebar-wrapper qs-sidebar-ac'
    ">
        <ul class="qs-sidebar-menu">
            <li class="qs-sidebar-menu-item" v-for="(item, index) in routes" :key="index">
                <div class="qs-sidebar-menu-title">
                    {{ item.meta.title }}
                    <span class="qs-sidebar-edit-icon" @click.stop="toDo('favoriteOprate', item)" v-if="item.meta.is_customize == 1"></span>
                </div>
                <!-- <div :class="currentRoute == item.path ? 'qs-sidebar-menu-title qs-sidebar-menu-title-cursor qs-sidebar-active' : 'qs-sidebar-menu-title qs-sidebar-menu-title-cursor'" v-else @click="toDo('jump', item)">
					<span class="qs-sidebar-submenu-text">{{ item.meta.title }}</span>
					<span class="qs-sidebar-edit-icon" @click.stop="toDo('favoriteOprate', item)" v-if="item.meta.is_customize == 1"></span>
				</div> -->
                <SidebarSubmenu v-if="item.children && item.children.length > 0" :children="item.children"></SidebarSubmenu>
            </li>
        </ul>
        <favorites ref="refFavorites" :favorites-type="editFavorites" :title="title"></favorites>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import SidebarSubmenu from '@/layout/components/sidebar/sidebarSubmenu.vue'
import favorites from '@/components/favorites.vue'
import { getLeftMenuStat } from '@/api/public/index'
export default {
    name: 'sidebar',
    props: ['parentMenu'],
    data() {
        return {
            editFavorites: '',
            title: '人才收藏夹',
        }
    },
    components: {
        SidebarSubmenu,
        favorites,
    },
    watch: {
        $route(newValue) {
            let currentRoute = newValue.path
            if (currentRoute.indexOf('/talent') != -1) {
                this.getLeftMenuStatistics({ mark: 'talent' })
            } else if (currentRoute.indexOf('/job') != -1) {
                this.getLeftMenuStatistics({ mark: 'job' })
            } else if (currentRoute.indexOf('/process') != -1) {
                this.getLeftMenuStatistics({ mark: 'process' })
            }
        },
    },
    computed: {
        ...mapState({
            addRouters: (state) => state.routingTable.addRouters,
            isSideBar: (state) => state.layout.isSideBar,
            isZoom: (state) => state.layout.isZoom,
            rootMenuId: (state) => state.layout.rootMenuId,
        }),
        isShowZoom() {
            let currentPath = this.parentMenu
            let addRouters = this.addRouters
            var isShow = false
            addRouters.forEach((item) => {
                if (item.path == currentPath) {
                    isShow = item.meta.isSideBar
                }
            })
            this.SET_ISSIDEBAR(isShow)
            if (this.isSideBar) {
                // 有侧边栏
                // 如果侧边栏已经切换回去（不展示的情况） 值永远为false  侧边栏无法展示出来
                // 想要的效果 每次切换 重新展示侧边栏
                return this.isZoom
            } else {
                // 无侧边栏
                return false
            }
        },
        routes() {
            let routeList = [],
                route_num = {},
                currentRoute = this.currentRoute,
                routingTable = this.$store.state.routingTable
            const allMenu = routingTable.routers
                ? [...routingTable.routers]
                : []
            if (currentRoute.indexOf('/talent') != -1) {
                route_num = routingTable.route_talent_num
            } else if (currentRoute.indexOf('/job') != -1) {
                route_num = routingTable.route_job_num
            } else if (currentRoute.indexOf('/process') != -1) {
                route_num = routingTable.route_process_num
            }
            allMenu.forEach((res) => {
                if (res.path == this.parentMenu) {
                    if (res.children) {
                        res.children.forEach((child) => {
                            if (child.children && child.children.length > 0) {
                                child.children.forEach((childs) => {
                                    for (let key in route_num) {
                                        if (childs.meta.mark == key) {
                                            this.$set(
                                                childs['meta'],
                                                'num',
                                                route_num[key]
                                            )
                                        }
                                    }
                                    //人才、职位、进程显示统计数
                                    if (
                                        res.name == 'talent' ||
                                        res.name == 'job' ||
                                        res.name == 'process'
                                    ) {
                                        childs.meta.countIsshow = true
                                    } else {
                                        childs.meta.countIsshow = false
                                    }
                                })
                            }
                        })
                        routeList = [...res.children]
                    }
                }
            })
            // // console.log(routeList, 'routeList')
            return routeList
        },
        currentRoute() {
            return this.$route.meta.activeMenu
                ? this.$route.meta.activeMenu
                : this.$route.path
        },
    },
    methods: {
        ...mapMutations({
            SET_ISSIDEBAR: 'layout/SET_ISSIDEBAR',
            SET_ISZOOM: 'layout/SET_ISZOOM',
            SET_ROUTE_TALENT_NUM: 'routingTable/SET_ROUTE_TALENT_NUM',
            SET_ROUTE_JOB_NUM: 'routingTable/SET_ROUTE_JOB_NUM',
            SET_ROUTE_PROCESS_NUM: 'routingTable/SET_ROUTE_PROCESS_NUM',
        }),
        ...mapActions({
            getLeftMenuStatistics: 'routingTable/getLeftMenuStatistics',
        }),
        toDo(oprate, item) {
            switch (oprate) {
                case 'jump':
                    this.$router.push(item.path)
                    break
                case 'favoriteOprate':
                    if (item.path == '/talent/favorites') {
                        this.editFavorites = 'telentFavorites'
                        this.title = '人才收藏夹'
                    } else if (item.path == '/job/favorites') {
                        this.editFavorites = 'jobFavorites'
                        this.title = '职位收藏夹'
                    }
                    this.$refs.refFavorites.handleOpen(
                        'editFavorite',
                        this.editFavorites,
                        ''
                    )
                    break
                default:
                    break
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.qs-sidebar-wrapper {
    width: 235px;
    height: calc(100% - 70px);
    position: fixed;
    left: 0;
    top: 60px;
    transition: all 0.5s;
    &.qs-sidebar-ac {
        transform: translateX(-235px);
    }
    .qs-sidebar-menu {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        .qs-sidebar-menu-item {
            .qs-sidebar-menu-title {
                white-space: nowrap;
                display: block;
                height: 62px;
                line-height: 62px;
                padding-left: 24px;
                font-weight: bold;
                color: $themeTextColor1;
                font-size: 14px;
                border-top: 2px solid #f3f8ff;
                border-bottom: 2px solid #f3f8ff;
                &.qs-sidebar-active {
                    background: linear-gradient(
                        to right,
                        $themeColor1,
                        $themeColor2
                    );
                    border-radius: 10px;
                    .qs-sidebar-submenu-text {
                        color: #fff;
                    }
                    .qs-sidebar-submenu-num {
                        color: #fff;
                    }
                }
                &.qs-sidebar-menu-title-cursor {
                    cursor: pointer;
                }
                .qs-sidebar-edit-icon {
                    display: block;
                    width: 14px;
                    height: 14px;
                    background: url('@/assets/images/editFavoriteIcon.png')
                        center center no-repeat;
                    background-size: 100% 100%;
                    float: right;
                    margin: 23px 25px 0 0;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>

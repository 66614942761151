import layout from '@/layout/index.vue'
const process = {
	path: '/process',
	component: layout,
	name: 'process',
	redirect: '/process/classify',
	meta: {
		title: '进程',
		isSideBar: true
	},
	children: [{
		path: '/process/classify',
		component: () => import('@/views/process/classify/index.vue'),
		name: 'processClassify',
		redirect: '/process/classify/firstTryProcess',
		meta: {
			title: '人才状态'
		},
		children: [{
				path: '/process/classify/firstTryProcess',
				component: () => import('@/views/process/classify/firstTryProcess/index.vue'),
				name: 'classifyFirstTryProcess',
				redirect: '/process/classify/firstTryProcess/list',
				meta: {
					title: '初试'
				},
				children: [{
						path: '/process/classify/firstTryProcess/list',
						component: () => import(
							'@/views/process/classify/firstTryProcess/list.vue'),
						name: 'classifyFirstTryProcessList',
						meta: {
							title: '初试',
							isShow: true,
							activeMenu: '/process/classify/firstTryProcess'
						},
					},
					// {
					// 	path: '/job/classify/allJob/add',
					// 	component: () => import('@/views/job/classify/allJob/add.vue'),
					// 	name: 'jobClassifyAllJobAdd',
					// 	meta: {
					// 		title: '新增职位',
					// 		isShow: true,
					// 		activeMenu: '/job/classify/allJob'
					// 	},
					// },
				]
			},
			{
				path: '/process/classify/repeatTryProcess',
				component: () => import('@/views/process/classify/repeatTryProcess/index.vue'),
				name: 'classifyRepeatTryProcess',
				redirect: '/process/classify/repeatTryProcess/list',
				meta: {
					title: '复试'
				},
				children: [{
					path: '/process/classify/repeatTryProcess/list',
					component: () => import('@/views/process/classify/repeatTryProcess/list.vue'),
					name: 'classifyRepeatTryProcessList',
					meta: {
						title: '复试',
						isShow: true,
						activeMenu: '/process/classify/repeatTryProcess'
					},
				}]
			},
			{
				path: '/process/classify/finalTryProcess',
				component: () => import('@/views/process/classify/finalTryProcess/index.vue'),
				name: 'classifyRepeatTryProcess',
				redirect: '/process/classify/finalTryProcess/list',
				meta: {
					title: '终试'
				},
				children: [{
					path: '/process/classify/finalTryProcess/list',
					component: () => import('@/views/process/classify/finalTryProcess/list.vue'),
					name: 'classifyFinalTryProcessList',
					meta: {
						title: '终试',
						isShow: true,
						activeMenu: '/process/classify/finalTryProcess'
					},
				}]
			},
			{
				path: '/process/classify/entryProcess',
				component: () => import('@/views/process/classify/entryProcess/index.vue'),
				name: 'classifyRepeatTryProcess',
				redirect: '/process/classify/entryProcess/list',
				meta: {
					title: '入职'
				},
				children: [{
					path: '/process/classify/entryProcess/list',
					component: () => import('@/views/process/classify/entryProcess/list.vue'),
					name: 'classifyEntryProcessList',
					meta: {
						title: '入职',
						isShow: true,
						activeMenu: '/process/classify/entryProcess'
					},
				}]
			},
			{
				path: '/process/classify/reserveProcess',
				component: () => import('@/views/process/classify/reserveProcess/index.vue'),
				name: 'classifyRepeatTryProcess',
				redirect: '/process/classify/reserveProcess/list',
				meta: {
					title: '储备'
				},
				children: [{
					path: '/process/classify/reserveProcess/list',
					component: () => import('@/views/process/classify/reserveProcess/list.vue'),
					name: 'classifyReserveProcessList',
					meta: {
						title: '储备',
						isShow: true,
						activeMenu: '/process/classify/reserveProcess'
					},
				}]
			},
			{
				path: '/process/classify/eliminateProcess',
				component: () => import('@/views/process/classify/eliminateProcess/index.vue'),
				name: 'classifyRepeatTryProcess',
				redirect: '/process/classify/eliminateProcess/list',
				meta: {
					title: '淘汰'
				},
				children: [{
					path: '/process/classify/eliminateProcess/list',
					component: () => import('@/views/process/classify/eliminateProcess/list.vue'),
					name: 'classifyEliminateProcessList',
					meta: {
						title: '淘汰',
						isShow: true,
						activeMenu: '/process/classify/eliminateProcess'
					},
				}]
			},
		]
	}]
}
export default process

<template>
    <div class="qs-account-box">
        <div class="qs-account-title" ref="qs-account-title">系统提醒</div>
        <div class="qs-account-content">
            <div class="qs-table">
                <el-table v-loading="loading" stripe ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
                    <!-- <el-table-column type="selection" width="50" align="right" fixed="left"></el-table-column> -->
                    <el-table-column prop="content" label="提醒内容" width="250">
                        <template slot-scope="scope">
                            <!-- type 1提醒 2面试 -->
                            <span class="qs-style-warn" v-if="scope.row.type==1">{{ scope.row.content }}</span>
                            <span class="qs-style-interview qs-style-interview1" v-if="scope.row.type==2&&scope.row.category==1">{{ scope.row.content }}</span>
                            <span class="qs-style-interview qs-style-interview2" v-if="scope.row.type==2&&scope.row.category==2">{{ scope.row.content }}</span>
                            <span class="qs-style-interview qs-style-interview3" v-if="scope.row.type==2&&scope.row.category==3">{{ scope.row.content }}</span>
                            <span class="qs-style-interview qs-style-interview4" v-if="scope.row.type==2&&scope.row.category==4">{{ scope.row.content }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="is_expire" label="是否过期" width="130">
                        <template slot-scope="scope">
                            <span v-if="scope.row.is_expire==1">已过期</span>
                            <span v-if="scope.row.is_expire==0">未过期</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注"></el-table-column>
                    <el-table-column prop="remind_time" label="提醒时间" width="180"></el-table-column>
                </el-table>
                <div class="qs-table-pagination" ref="qs-table-pagination">
                    <el-pagination :current-page="page" :page-sizes="[10, 20, 50, 100]" :page-size="per_page" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { tableHeightMixin } from '@/mixins/tableHeightMixin'
import { dateFormatter } from '@/utils/index'
import { getRemindRecordList } from '@/api/public/index'
export default {
    mixins: [tableHeightMixin],
    data() {
        return {
            form: {
                user_id: '',
                operate_start_time: '',
                operate_end_time: '',
                keyword: '',
            },
            date: '',
            loading: false,
            tableData: [],
            total: 0,
            page: 1,
            per_page: 10,
            select_id_arr: [],
        }
    },
    mounted() {
        // this.initData()
        this.$nextTick(() => {
            this.tabelHeight(['qs-account-title', 'qs-table-pagination'])
        })
    },
    methods: {
        dateChange(e) {
            if (e) {
                this.form.operate_start_time = e[0]
                this.form.operate_end_time = e[1]
            } else {
                this.date = ''
                this.form.operate_start_time = ''
                this.form.operate_end_time = ''
            }
            this.initData()
        },
        initData() {
            let params = {}
            params.page = this.page
            params.per_page = this.per_page
            getRemindRecordList(params)
                .then((res) => {
                    if (res.code == '0000') {
                        this.tableData = res.data.item
                        this.tableData.forEach((item) => {
                            this.$set(
                                item,
                                'remind_time',
                                dateFormatter(item.remind_time * 1000)
                            )
                        })
                        this.total = res.data.total
                    }
                })
                .catch(() => {})
        },
        handleSelectionChange(val) {
            if (val && val.length > 0) {
                val.forEach((item) => {
                    this.select_id_arr.push(item.log_id)
                })
            }
        },
        handleSizeChange(val) {
            this.per_page = val
            this.initData()
        },
        handleCurrentChange(val) {
            this.page = val
            this.initData()
        },
        toDo(row, oprate) {
            switch (oprate) {
                case 'export':
                    this.exportExcel()
                    break
                case 'delete':
                    this.deleteLog(row)
                    break
                default:
                    break
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.qs-date-picker {
    margin-right: 15px;
    ::v-deep .el-input__inner {
        width: 240px;
    }
}
.qs-account-box {
    .qs-account-title {
        color: #333333;
        font-size: 16px;
        padding: 22px 24px;
        border-bottom: 1px solid #e3eef6;
        box-sizing: border-box;
    }
    .qs-account-content {
        box-sizing: border-box;
        padding: 24px;
        .qs-opration-box {
            padding: 15px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .qs-total-result {
                color: #0c2556;
                font-size: 14px;
            }
        }
        .qs-table {
            background: $bodyBgColor;
            position: relative;
            .qs-style-warn {
                background: rgba(104, 116, 242, 0.1);
                color: #6874f2;
                display: inline-block;
                padding: 0 10px;
                font-size: 14px;
                border-radius: 6px;
            }
            .qs-style-interview {
                display: inline-block;
                padding: 0 10px;
                font-size: 14px;
                border-radius: 6px;
                &.qs-style-interview1 {
                    background: rgba(26, 179, 148, 0.1);
                    color: #1ab394;

                    .qs-calendar-txt {
                    }
                }
                &.qs-style-interview2 {
                    background: rgba(104, 116, 242, 0.1);
                    color: #6874f2;
                    .qs-calendar-txt {
                    }
                }
                &.qs-style-interview3 {
                    color: #23c6c8;
                    background: rgba(35, 198, 200, 0.1);
                    .qs-calendar-txt {
                    }
                }
                &.qs-style-interview4 {
                    color: #ff6262;
                    background: rgba(255, 153, 153, 0.1);
                    .qs-calendar-txt {
                    }
                }
            }

            ::v-deep .el-table td.el-table__cell,
            ::v-deep .el-table th.el-table__cell.is-leaf {
                border-bottom: none;
                margin-bottom: 5px;
            }
            .qs-delete {
                color: #333333;
                font-size: 14px;
                cursor: pointer;
            }
            .el-table {
                // background-color: $bodyBgColor;
                border-radius: 6px;
                &::before {
                    display: none;
                }
                .qs-label-detail {
                    color: #0d9d45 !important;
                    cursor: pointer;
                    font-size: 14px;
                }
                .qs-table-name {
                    color: #6874f2;
                    cursor: pointer;
                    position: relative;
                    &::before {
                        content: '';
                        display: none;
                        width: 14px;
                        height: 14px;
                        background: url('@/assets/images/edit.png') left center
                            no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        top: 0;
                        left: -18px;
                    }
                    &:hover::before {
                        display: block;
                    }
                }
                .qs-table-opration-btn {
                    width: 14px;
                    height: 14px;
                    cursor: pointer;
                    position: relative;
                    .qs-table-oprations {
                        width: 140px;
                        height: 280px;
                        background: #ffffff;
                        box-shadow: 0px 0px 5px 1px rgba(220, 220, 220, 0.35);
                        border-radius: 6px;
                    }
                }
                // ::v-deep th.el-table__cell > .cell {
                //     color: #808eaa;
                // }
                // ::v-deep td.el-table__cell div {
                //     color: #333333;
                // }
                // ::v-deep .el-table__header-wrapper {
                //     border-radius: 10px;
                //     .el-table__header {
                //         thead {
                //             tr {
                //                 th:nth-child(3) {
                //                     .cell {
                //                         padding-left: 18px;
                //                     }
                //                 }
                //             }
                //         }
                //     }
                // }
                // ::v-deep .el-table__body-wrapper {
                //     border-radius: 10px;
                // }
                // ::v-deep .el-table__body {
                //     //-webkit-border-horizontal-spacing: 13px;  // 水平间距
                //     -webkit-border-vertical-spacing: 5px; // 垂直间距 设置的是行间距
                // }
                // thead th,
                // ::v-deep .el-table__row td {
                //     &:first-child {
                //         border-radius: 10px 0 0 10px;
                //     }
                //     &:nth-child(3) {
                //         padding-left: 0;
                //         .cell {
                //             padding-left: 18px;
                //         }
                //     }
                // }
                // ::v-deep .el-table__row > td {
                //     &:last-child {
                //         border-radius: 0 10px 10px 0;
                //     }
                // }
            }
        }
    }
}
::v-deep .el-table {
    thead {
        color: #606266;
    }
}
</style>

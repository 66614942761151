/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-03-10 09:00:28
 * @LastEditors: pj
 * @LastEditTime: 2023-05-06 11:59:48
 */
import router from "./router";
import store from "./store";
import getPageTitle from "@/utils/get-page-title.js";
import { getSelectCompanyStateId, getToken } from "./utils/auth";
var whiteList = [
    "/login",
    "/companyApply",
    "/invateLink",
    "/retrievePassword",
    "/404",
];
router.beforeEach(async (to, from, next) => {
    from.meta.isSelected = false;
    to.meta.isSelected = true;
    document.title = getPageTitle(to.meta.title);
    // 通过token查看登录没有 如果有token并且选择过企业 就进入系统
    const hasToken = getToken();
    const isSelectCompany = getSelectCompanyStateId();
    if (hasToken && isSelectCompany) {
        if (router.history.current.path.indexOf('/inviteLink') == -1) {// 邀请链接页面不调用
            const minute = new Date().getMinutes()
            if (minute % 5 == 0) {//5的倍数调用
                store.dispatch("common/initRemindNotice");//切换页面要调一次消息通知
            }
            if (store.state.common.firstRequest) {//第一次进入系统 && 刷新页面时
                store.dispatch("common/getClassifyData");//获取公共数据
                store.dispatch("common/getContact");//获取联系人列表
                store.dispatch("common/getOperateAuth");//获取全局操作权限
                store.dispatch("user/initUserInfo");//获取用户信息
                store.dispatch("routingTable/getLeftMenuStatistics", { mark: 'talent' });//获取左侧菜单统计数据
                store.dispatch("routingTable/getLeftMenuStatistics", { mark: 'job' });//获取左侧菜单统计数据
                store.dispatch("routingTable/getLeftMenuStatistics", { mark: 'process' });//获取左侧菜单统计数据
            }
        }
        //进入系统
        // 查看进入的是不是登录页面 如果是登录页面则进入系统首页
        if (to.path === "/login") {
            next({
                path: "/",
            });
        } else {
            // 查看是否有用户信息 如果有则进入系统 如果没有 请求获取用户信息
            const hasGetUserInfo = store.state.user.name;
            if (hasGetUserInfo) {
                next();
            } else {
                // 设置用户信息
                var name = store.state.user.userInfo.user_name
                    ? store.state.user.userInfo
                    : JSON.parse(store.state.user.userInfo);
                store.commit("user/SET_USERNAME", name.user_name);
                let { data } = await store.dispatch("routingTable/getMenuList");
                store.dispatch("routingTable/GenerateRoutes", data).then(() => {
                    let routersList = store.state.routingTable.addRouters;
                    router.addRoutes(routersList);
                    next({
                        ...to,
                        replace: true,
                    });
                });
            }
        }
    } else {
        if (whiteList.indexOf(to.path) != -1 || to.path.indexOf('/inviteLink') != -1) {
            next();
        } else {
            next(`/login?redirect=${to.path}`);
        }
    }
});

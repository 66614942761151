import layout from '@/layout/index.vue'
const interview = {
    path: '/interview',
    component: layout,
    name: 'interview',
    redirect: '/interview/arrange',
    meta: { title: '面试', isSideBar: false },
    children: [
        {
            path: '/interview/arrange',
            component: () => import('@/views/interview/arrange/index.vue'),
            name: 'interviewArrange',
            meta: { title: '面试安排' }
        }
    ]
}
export default interview
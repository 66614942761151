/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-02-28 18:02:55
 * @LastEditors: pj
 * @LastEditTime: 2023-04-20 17:41:02
 */
import vue from 'vue'
// 菜单列表
function menuList (data) {
    return vue.$get('/saas/api/menu/list', data)
}
// 登录接口
function login (data) {
    return vue.$post('/saas/api/user/login', data)
}
// 退出登录 
function loginOut (data) {
    return vue.$post('/saas/api/user/logout', data)
}
// 获取用户下的企业列表
function companyList (data) {
    return vue.$get('/saas/api/user/getCompanyList', data)
}
// 用户选择企业
function enterCompany (data) {
    return vue.$post('/saas/api/user/enterCompany', data)
}

//发送验证码 /saas/api/open/sendSmsCode
function sendSmsCode (data) {
    return vue.$post('/saas/api/open/sendSmsCode', data)
}

// 找回密码 
function retrievePassword (data) {
    return vue.$post('/saas/api/user/retrievePassword', data)
}

// 操作日志 
function getOperateLogList (data) {
    return vue.$get('/saas/api/user/getOperateLogList', data)
}

// 导出日志 
function exportOperateLog (data) {
    return vue.$download('post', '/saas/api/user/exportOperateLog', data)
}
//创建邀请链接  
function createInviteLink (data) {
    return vue.$post('/saas/api/user/createInviteLink', data)
}
//获取邀请信息   
function getInviteInfo (data) {
    return vue.$get('/saas/api/user/getInviteInfo', data)
}
//申请试用   
function applyForTrial (data) {
    return vue.$post('/saas/api/user/applyForTrial', data)
}
//加入企业 
function joinCompany (data) {
    return vue.$post('/saas/api/user/joinCompany', data)
}
//获取微信绑定二维码 
function getWechatBindQrcode (data) {
    return vue.$post('/saas/api/user/getWechatBindQrcode', data)
}
//解绑微信 
function unbindWechat (data) {
    return vue.$post('/saas/api/user/unbindWechat', data)
}
//获取账号信息 
function getAccountInfo (data) {
    return vue.$get('/saas/api/user/getAccountInfo', data)
}
export {
    loginOut,
    enterCompany,
    companyList,
    menuList,
    login,
    sendSmsCode,
    retrievePassword,
    getOperateLogList,
    exportOperateLog,
    createInviteLink,
    applyForTrial,
    joinCompany,
    getInviteInfo,
    getWechatBindQrcode,
    unbindWechat,
    getAccountInfo
}

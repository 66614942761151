<template>
    <div class="qs-logo">
        <router-link class="qs-login-text" to="/">ATS 管理系统 </router-link>
    </div>
</template>

<script>
export default {
    name: 'logo',
}
</script>

<style lang="scss" scoped>
.qs-logo {
    width: 235px;
    height: 60px;
    background: #fff;
    color: $themeTextColor1;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    cursor: pointer;
    .qs-login-text {
        display: block;
        width: 100%;
        height: 100%;
        line-height: 60px;
        padding-left: 25px;
        color: $themeTextColor1;
        font-weight: bold;
        font-size: 20px;
        color: #1c2f69;
    }
}
</style>
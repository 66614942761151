/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-02-28 18:02:55
 * @LastEditors: pj
 * @LastEditTime: 2023-04-27 17:46:43
 */
export const tableHeightMixin = {
    data () {
        return {
            tableH: 0
        }
    },
    computed: {
        tabelHeight () {
            return (val) => {
                this.$nextTick(() => {
                    const docHeight = document.documentElement.clientHeight;
                    let eleList = val
                    let eleTotalHeight = 0
                    eleList.forEach((item) => {
                        eleTotalHeight += this.$refs[item].clientHeight
                    })
                    //20-固定上边距  70-固定header高度
                    let tabelHeight = docHeight - eleTotalHeight - 20 - 70;
                    this.tableH = tabelHeight
                });
            }
        }
    },
    mounted () { },
    methods: {},
}

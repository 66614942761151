<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-02-27 09:04:55
 * @LastEditors: pj
 * @LastEditTime: 2023-05-04 11:27:10
-->
<template>
    <div class="qs-menu-wrapper">
        <ul class="qs-menu-ul clearfix">
            <li :class="isZoom?'qs-zoom':'qs-zoom-ac'" @click="_debounce(handleIsZoom, 500)"></li>
            <li class="qs-menu-li" v-for="(item, index) in addRouters" :key="index">
                <div :class="item.path == parentMenu ? 'qs-menu-text qs-menu-ac' : 'qs-menu-text'" @click="handleJump(item)">{{ item.meta.title }}</div>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
    name: 'topMenu',
    props: ['parentMenu'],
    data() {
        return {
            params: {
                mark: 'talent',
            },
        }
    },
    computed: {
        ...mapState({
            addRouters: (state) => state.routingTable.addRouters,
            isSideBar: (state) => state.layout.isSideBar,
            isZoom: (state) => state.layout.isZoom,
        }),
        currentRoute() {
            return this.$route
        },
    },
    methods: {
        ...mapMutations({
            SET_ISZOOM: 'layout/SET_ISZOOM',
            SET_OPERATE_AUTH: 'common/SET_OPERATE_AUTH',
        }),
        ...mapActions({
            getOperateAuth: 'common/getOperateAuth',
        }),
        handleIsZoom() {
            // isSideBar false 不展示左侧菜单 true 展示左侧菜单
            if (this.isSideBar) {
                this.SET_ISZOOM(!this.isZoom)
            }
        },
        handleJump(item) {
            // this.getOperateAuth();
            // // console.log('点击顶部导航：', item.path)
            this.$router.push(item.path)
        },
    },
}
</script>

<style lang="scss" scoped>
.qs-menu-wrapper {
    width: 80%;
    height: 60px;
    overflow-x: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    .qs-menu-ul {
        white-space: nowrap;
        height: 60px;
        .qs-zoom {
            width: 50px;
            height: 60px;
            cursor: pointer;
            display: inline-block;
            background: url('@assets/images/layout/zoom_out.png') no-repeat
                center center;
            vertical-align: middle;
        }
        .qs-zoom-ac {
            width: 50px;
            height: 60px;
            cursor: pointer;
            display: inline-block;
            background: url('@assets/images/layout/zoom_in.png') no-repeat
                center center;
            vertical-align: middle;
        }
        .qs-menu-li {
            vertical-align: middle;
            display: inline-block;
            &:hover {
                .qs-menu-text {
                    background-image: linear-gradient(
                        60deg,
                        $themeColor1,
                        $themeColor2
                    );
                    background-clip: text;
                    color: transparent;
                }
            }
            .qs-menu-text {
                display: block;
                color: $themeTextColor1;
                font-size: 14px;
                font-weight: 700;
                padding: 0 25px;
                height: 60px;
                line-height: 60px;
                cursor: pointer;
                position: relative;
                &.qs-menu-ac {
                    position: relative;
                    background-image: linear-gradient(
                        60deg,
                        $themeColor1,
                        $themeColor2
                    );
                    background-clip: text;
                    color: transparent;
                    &::before {
                        content: '';
                        position: absolute;
                        bottom: -1px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 32px;
                        height: 3px;
                        background: linear-gradient(
                            to right,
                            $themeColor1,
                            $themeColor2
                        );
                    }
                }
            }
        }
    }
}
</style>

<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-04-21 13:32:18
 * @LastEditors: pj
 * @LastEditTime: 2023-05-10 10:31:18
-->
<template>
    <div class="qs-news-dialog" v-if="isSystemDialog">
        <div class="qs-news-title">系统提示<span class="qs-title-warn" v-if="remindInfo.wait_count>0">您还有 {{remindInfo.wait_count}} 条待办事项</span></div>
        <div class="qs-remind-list">
            <div class="qs-remind-remark qs-remind-content">
                <span class="text1">内容：</span>
                <span :class="remindInfo.type==2?'text3 color4':remindInfo.category==1?'text3 color1':remindInfo.category==2?'text3 color2':remindInfo.category==3?'text3 color3':'text3 color4'">{{ remindInfo.content }}</span>
            </div>
            <div class="qs-remind-remark">
                <span class="text1">时间：</span>
                <span>{{ remindInfo.remind_time }}</span>
            </div>
            <div class="qs-remind-remark">
                <span class="text1">备注：</span>
                <span class="text2">{{ remindInfo.remark }}</span>
            </div>
            <div class="qs-remind-oprate">
                <span class="qs-see-btn qs-see-all" @click="gotoRemindList()">查看全部</span>
                <span class="qs-see-btn qs-see-close" @click="handleClose">知道了</span>
            </div>
        </div>
    </div>
</template>

<script>
import { dateFormatter } from '@/utils/index'
export default {
    data() {
        return {
            isSystemDialog: false,
            remindInfo: {
                category: 0,
                content: '',
                id: 40,
                remark: '',
                remind_time: 0,
                type: 2,
            },
        }
    },
    methods: {
        // 打开弹窗
        handleOpen(data) {
            // console.log(data, 'data')
            data.remind_time = dateFormatter(data.remind_time * 1000)
            this.remindInfo = data
            this.isSystemDialog = true
        },
        // 关闭页面
        handleClose() {
            this.$emit('updateCalendarData')
            this.isSystemDialog = false
        },
        gotoRemindList() {
            this.$emit('getRemindList')
        },
    },
}
</script>

<style lang="scss" scoped>
.qs-news-dialog {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 3000;
    background: #fff;
    /* border: 1px solid #105fff; */
    border-radius: 4px;
    box-shadow: 0 0 8px 0 #dfdfdf;
    .qs-news-title {
        color: #fff;
        font-size: 15px;
        box-sizing: border-box;
        padding: 10px 12px 11px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background: #105fff url('@/assets/images/systemWarnIcon.png') 10px
            center no-repeat;
        background-size: 18px 18px;

        .qs-title-warn {
            font-size: 12px;
            color: #e1ebff;
        }
    }
    .qs-remind-list {
        padding: 5px 10px 10px;
        min-width: 245px;
        .qs-remind-content {
            margin: 7px 0 10px;
            .qs-text {
                display: inline-block;
                width: auto;
                padding: 2px 10px;
                font-size: 14px;
                border-radius: 4px;
                background: linear-gradient(
                    90deg,
                    rgba(50, 200, 231, 0.1),
                    rgba(104, 116, 242, 0.1)
                );
                &.qs-first-interview {
                    color: #23c6c8;
                    background: rgba(35, 198, 200, 0.1);
                }
                &.qs-second-interview {
                    color: #1ab394;
                    background: rgba(26, 179, 148, 0.1);
                }
                &.qs-third-interview {
                    color: #ff9999;
                    background: rgba(255, 153, 153, 0.1);
                }
                .qs-in-text {
                    background: linear-gradient(0deg, #32c8e7 0%, #6874f2 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
        .qs-remind-remark {
            font-size: 14px;
            color: #333;
            margin-bottom: 3px;
            display: flex;
            &.qs-remind-content {
                margin-bottom: 6px;
            }
            .text1 {
                color: #8f8f8f;
            }
            .text2 {
                display: block;
                max-width: 240px;
                text-align: justify;
            }
            .text3 {
                &.color1 {
                    color: #23c6c8;
                }
                &.color2 {
                    color: #1ab394;
                }
                &.color3 {
                    color: #ff9999;
                }
                &.color4 {
                    color: #105fff;
                }
            }
        }
        .qs-remind-time {
            font-size: 14px;
            color: #999;
            margin-top: 15px;
        }
        .qs-remind-oprate {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px 0 3px;
            .qs-see-btn {
                font-size: 12px;
                /* border: 1px solid #6874f2; */
                background: #ebf2ff;
                color: #666;
                border-radius: 4px;
                padding: 7px 0;
                cursor: pointer;
                width: 78px;
                text-align: center;
                &.qs-see-close {
                    margin-left: 10px;
                    /* border: 1px solid #666; */
                    color: #fff;
                    background: #105fff;
                    padding: 8px 0;
                }
            }
        }
    }
}

::v-deep .el-dialog__header {
    text-align: left;
}
::v-deep .el-dialog--center .el-dialog__body {
    padding-top: 15px;
}
</style>

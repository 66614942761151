import layout from '@/layout/index.vue'
const home = {
    path: '/home',
    component: layout,
    name: 'home',
    redirect: '/home/workbench',
    meta:{title: '首页',isSideBar:false},
    children:[
        {
            path: '/home/workbench',
            component: () => import('@/views/home/workbench/index.vue'),
            name: 'homeWorkbench',
            meta:{title: '首页'},
        }
    ]
}
export default home
<template>
    <el-dialog :close-on-click-modal="false" append-to-body title="" :visible.sync="passwardVisible" center width="464px" :before-close="handleClose">
        <div slot="title">
            <div class="qs-invite-link-title">更换密码</div>
        </div>
        <el-form label-width="100px" ref="form" :model="form" :rules="rules" auto-complete="on" label-position="left">
            <el-form-item prop="cur_password" label="当前密码"><el-input placeholder="请输入当前密码" show-password v-model="form.cur_password"></el-input></el-form-item>
            <el-form-item prop="new_password" label="新密码"><el-input placeholder="请输入当前密码" show-password v-model="form.new_password"></el-input></el-form-item>
            <el-form-item prop="confirm_password" label="确认新密码"><el-input placeholder="请输入当前密码" show-password v-model="form.confirm_password"></el-input></el-form-item>
            <div class="qs-btn-box">
                <el-button @click="onSubmit('form')" :disabled="passwardChangeStatus" class="el-button--primary qs-btn-linear-gradient" size="medium">确定</el-button>
                <el-button @click="cancel()" class="qs-search-btn-reset" type="info" size="medium">取消</el-button>
            </div>
        </el-form>
    </el-dialog>
</template>

<script>
import { sendSmsCode } from '@/api/user/index'
export default {
    data() {
        return {
            passwardVisible: false,
            form: {
                cur_password: '',
                new_password: '',
                confirm_password: '',
            },
            rules: {
                cur_password: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入手机号',
                    },
                ],
                new_password: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入验证码',
                    },
                ],
                confirm_password: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入验证码',
                    },
                ],
            },
            passwardChangeStatus: false,
        }
    },
    methods: {
        // 打开弹窗
        handleOpen() {
            this.passwardVisible = true
        },
        // 关闭页面
        handleClose() {
            this.passwardVisible = false
        },
        cancel() {
            this.passwardVisible = false
            this.$emit('updateEditPasward', false)
        },
        onSubmit(formName) {
            this.passwardChangeStatus = true
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.passwardChangeStatus = false
                    this.$emit('changePasswardFun', this.form)
                } else {
                    this.passwardChangeStatus = false
                    return false
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.qs-invite-link-title {
    text-align: left;
    color: #333;
    font-size: 18px;
    font-weight: bold;
    box-sizing: border-box;
    padding-left: 6px;
}
.qs-verify-code-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .qs-verify-code-input {
        width: 60%;
    }
    .qs-verify-code-btn {
        width: 35%;
        background: #e3eef6;
        border-color: #e3eef6;
        color: #666666;
        padding: 12px 0;
    }
}
.qs-btn-box {
    text-align: center;
    border-top: 1px solid #f3f8ff;
    padding-top: 15px;
}
::v-deep .el-dialog--center .el-dialog__body {
    padding-bottom: 15px;
}
</style>

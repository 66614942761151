import vue from 'vue'
// 获取配置  1|人才配置，2|职位配置，3|进程配置
function getConfig(data) {
	return vue.$get('/saas/api/configure/getConfig', data)
}
// 保存人才配置
function saveTalentConfig(data) {
	return vue.$post('/saas/api/configure/saveTalentConfig', data)
}
//保存职位配置
function saveJobConfig(data) {
	return vue.$post('/saas/api/configure/saveJobConfig', data)
}
//保存进程配置 
function saveProcessConfig(data) {
	return vue.$post('/saas/api/configure/saveProcessConfig', data)
}
//获取标签列表
function getTagList(data) {
	return vue.$get('/saas/api/configure/systemTag/getList', data)
}
//添加标签
function systemTagAdd(data) {
	return vue.$post('/saas/api/configure/systemTag/add', data)
}
//编辑标签
function systemTagEdit(data) {
	return vue.$post('/saas/api/configure/systemTag/edit', data)
}
//删除标签
function systemTagDelete(data) {
	return vue.$post('/saas/api/configure/systemTag/delete', data)
}
// 获取用户列表
function getUserList(data) {
	return vue.$get('/saas/api/user/getList', data)
}
// 获取角色列表 
function getRoleList(data) {
	return vue.$get('/saas/api/role/getList', data)
}
// 获取全部角色
function getAllRole(data) {
	return vue.$get('/saas/api/publics/getAllRole', data)
}
// 添加角色 
function roleAdd(data) {
	return vue.$post('/saas/api/role/add', data)
}
// 编辑角色 
function roleEdit(data) {
	return vue.$post('/saas/api/role/edit', data)
}
// 删除角色 
function roleDelete(data) {
	return vue.$post('/saas/api/role/delete', data)
}
// 获取权限
function getRoleAuth(data) {
	return vue.$get('/saas/api/role/getAuth', data)
}
// 保存权限  
function saveRoleAuth(data) {
	return vue.$post('/saas/api/role/saveAuth', data)
}
// 获取角色下所属用户 
function getRoleUser(data) {
	return vue.$get('/saas/api/role/getUser', data)
}
// 用户授权 
function authorize(data) {
	return vue.$post('/saas/api/user/authorize', data)
}
// 切换 锁定 
function toggleLock(data) {
	return vue.$post('/saas/api/user/toggleLock', data)
}
// 移交工作 
function handoverWork(data) {
	return vue.$post('/saas/api/user/handoverWork', data)
}
//删除用户   
function deleteUser(data) {
	return vue.$post('/saas/api/user/delete', data)
}
export {
	getConfig,
	saveTalentConfig,
	saveJobConfig,
	saveProcessConfig,
	getTagList,
	systemTagAdd,
	systemTagEdit,
	systemTagDelete,
	getUserList,
	getRoleList,
	roleAdd,
	roleEdit,
	roleDelete,
	getRoleAuth,
	saveRoleAuth,
	getRoleUser,
	getAllRole,
	authorize,
	toggleLock,
	handoverWork,
	deleteUser
}

import layout from '@/layout/index.vue'
const wholeNetwork = {
    path: '/wholeNetwork',
    component: layout,
    name: 'wholeNetwork',
    redirect: '/wholeNetwork/talentSearch',
    meta:{title: '全网',isSideBar:false},
    children:[
        {
            path: '/wholeNetwork/talentSearch',
            component: () => import('@/views/wholeNetwork/talentSearch/index.vue'),
            name: 'wholeNetworkTalentSearch',
            meta:{title: '全网'},
        }
    ]
}
export default wholeNetwork
import {
    getClassify,
    getTagList,
    getContactList,
    getOperateAuthList,
    getRemindNotice
} from "@/api/public/index";
const state = {
    options_sex: [], //性别
    options_education: [], //学历
    options_marriage: [], //婚姻状况
    options_talent_status: [], //人才状态
    options_language: [], //语言能力
    options_talent_source: [], //人才来源
    options_work_nature: [], //工作性质
    options_job_priority: [], //职位优先级
    options_job_status: [], //职位状态
    options_district: [], //工作地区
    options_job_category: [], //职位类别
    options_work_experience: [], //工作经验
    options_tag: [], //标签
    options_process_status: [], //职位状态
    options_trade_category: [], //行业类别
    options_contact_list: [], //联系人列表
    options_operate_auth: {}, //操作权限
    options_repeat_way: [], //重复方式
    options_remind_category: [], //提醒类别
    options_remind_duration: [], //提醒时长
    firstRequest: true,
}
const mutations = {
    SET_OPTIONS_SEX: (state, data) => {
        state.options_sex = data
    },
    SET_OPTIONS_EDUCATION: (state, data) => {
        state.options_education = data
    },
    SET_OPTIONS_MARRIAGE: (state, data) => {
        state.options_marriage = data
    },
    SET_OPTIONS_TALENT_STATUS: (state, data) => {
        state.options_talent_status = data
    },
    SET_OPTIONS_LANGUAGE: (state, data) => {
        state.options_language = data
    },
    SET_OPTIONS_TALENT_SOURCE: (state, data) => {
        state.options_talent_source = data
    },
    SET_OPTIONS_WORK_NATURE: (state, data) => {
        state.options_work_nature = data
    },
    SET_OPTIONS_JOB_PRIORITY: (state, data) => {
        state.options_job_priority = data
    },
    SET_OPTIONS_JOB_STATUS: (state, data) => {
        state.options_job_status = data
    },
    SET_OPTIONS_DISTRICT: (state, data) => {
        state.options_district = data
    },
    SET_OPTIONS_JOB_CATEGORY: (state, data) => {
        state.options_job_category = data
    },
    SET_OPTIONS_WORK_EXPERIENCE: (state, data) => {
        state.options_work_experience = data
    },
    SET_OPTIONS_PROCESS_STATUS: (state, data) => {
        state.options_process_status = data
    },
    SET_OPTIONS_TRADE_CATEGORY: (state, data) => {
        state.options_trade_category = data
    },
    SET_CONTACT_LIST: (state, data) => {
        state.options_contact_list = data
    },
    SET_OPERATE_AUTH: (state, data) => {
        state.options_operate_auth = data
    },
    SET_OPTIONS_REMIND_CATEGORY: (state, data) => {
        state.options_remind_category = data
    },
    SET_OPTIONS_REMIND_DURATION: (state, data) => {
        state.options_remind_duration = data
    },
    SET_OPTIONS_REPEAT_WAY: (state, data) => {
        state.options_repeat_way = data
    },
    SET_FIRST_REQUEST: (state, data) => {
        state.firstRequest = data
    },
    SET_TAGLISTS: (state, data) => {
        state.options_tag = data
    }
}

const actions = {
    // 获取分类
    getClassifyData ({
        commit
    }) {
        return new Promise((resolve, reject) => {
            let params = {
                type: "sex,education,marriage,talent_status,language,talent_source,work_nature,job_priority,job_status,district,job_category,work_experience,process_status,trade_category,remind_category,remind_duration,repeat_way"
            }
            getClassify(params).then((result) => {
                if (result.code == '0000') {
                    var classAry = result.data
                    var typeAry = params.type.split(',')
                    typeAry.forEach((item, index) => {
                        var arrName = 'SET_OPTIONS_' + item.toUpperCase()
                        commit(arrName, classAry[item])
                    })
                    commit('SET_FIRST_REQUEST', false)
                }
                resolve(result)
            }).catch((err) => {
                // console.log(err)
                reject()
            });
        })
    },
    //获取标签列表 
    getTags ({
        commit
    }) {
        return new Promise((resolve, reject) => {
            getTagList().then((result) => {
                if (result.code == '0000') {
                    var tagAry = result.data
                    commit('SET_TAGLISTS', tagAry)
                }
                resolve(result)
            }).catch((err) => {
                // console.log(err)
                reject()
            });
        })
    },
    //获取联系人列表 
    getContact ({
        commit
    }) {
        return new Promise((resolve, reject) => {
            getContactList().then((result) => {
                if (result.code == '0000') {
                    var contactAry = result.data
                    commit('SET_CONTACT_LIST', contactAry)
                }
                resolve(result)
            }).catch((err) => {
                // console.log(err)
                reject()
            });
        })
    },
    //获取操作权限 
    getOperateAuth ({
        commit
    }) {
        return new Promise((resolve, reject) => {
            getOperateAuthList().then((result) => {
                if (result.code == '0000') {
                    var operate_auth = result.data
                    commit('SET_OPERATE_AUTH', operate_auth)
                }
                resolve(result)
            }).catch((err) => {
                // console.log(err)
                reject()
            });
        })
    },
    //获取消息通知 
    initRemindNotice ({
        commit
    }) {
        return new Promise((resolve, reject) => {
            getRemindNotice().then((result) => {
                resolve(result)
            }).catch((err) => {
                // console.log(err)
                reject()
            });
        })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}

<template>
    <el-dialog :close-on-click-modal="false" :visible.sync="isCompanyDialog" width="390px" center :show-close="false" top="30vh">
        <div slot="title" class="qs-select-company-title">
            公司选择
            <i class="qs-title-close el-icon-close" @click="isCompanyDialog = false"></i>
        </div>
        <div>
            <el-radio-group class="qs-select-radio-wrapper" v-model="companyId">
                <el-radio :label="item.company_id" v-for="item in companyList" :key="item.company_id" :disabled="item.is_lock == 1">{{ item.company_name }}</el-radio>
            </el-radio-group>
        </div>
        <div class="qs-btns-wrapper" slot="footer">
            <el-button class="qs-btn-confirm qs-btn-linear-gradient" @click="handleSetCompany">确认</el-button>
            <el-button class="qs-btn-cancel" @click="isCompanyDialog = false">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { companyList, enterCompany } from '@/api/user'
import {
    setToken,
    setUserInfo,
    getRequestData,
    setRequestData,
} from '@/utils/auth'
import { mapMutations } from 'vuex'
export default {
    data() {
        return {
            companyId: 0,
            isCompanyDialog: false,
            companyList: [],
        }
    },
    mounted() {
        this.companyId = getRequestData('company_id')
    },
    methods: {
        ...mapMutations({
            SET_TOKEN: 'user/SET_TOKEN',
            SET_USERINFO: 'user/SET_USERINFO',
        }),
        // 企业选择确认
        handleSetCompany() {
            let currentPath = this.$route.path
            if (this.companyId > 0) {
                enterCompany({ company_id: this.companyId }).then((res) => {
                    if (res.code == '0000') {
                        let role_name = res.data.role_name
                        setRequestData('role_name', role_name)
                        setRequestData('company_id', this.companyId)
                        if (currentPath == '/login') {
                            var userInfo = {
                                is_creator: res.data.is_creator,
                                user_id: res.data.user_id,
                                user_name: res.data.user_name,
                            }
                            // 设置用户token
                            setToken(res.data.token)
                            this.SET_TOKEN(res.data.token)
                            // 设置用户登录信息
                            setUserInfo(JSON.stringify(userInfo))
                            this.SET_USERINFO(userInfo)
                            this.$router.push('/')
                        } else {
                            setToken(res.data.token)
                            this.SET_TOKEN(res.data.token)
                            this.isCompanyDialog = false
                            this.$router.push('/')
                            // 切换企业后更新数据
                            window.location.reload()
                        }
                    }
                })
            }
        },
        //获取企业列表
        getCompanyList() {
            companyList()
                .then((res) => {
                    if (res.code == '0000') {
                        this.companyList = res.data
                        this.isCompanyDialog = true
                    }
                })
                .catch((err) => {
                    // console.log(err)
                })
        },
        // 打开弹窗
        handleOpen() {
            this.companyId = parseInt(getRequestData('company_id'))
            this.getCompanyList()
        },
        // 关闭页面
        handleClose() {
            this.isCompanyDialog = false
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
    padding: 30px 20px 0px 30px;
    .qs-select-company-title {
        text-align: left;
        position: relative;
        color: #333333;
        font-size: 18px;
        font-weight: 800;
        .qs-title-close {
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            color: #999999;
            font-size: 24px;
            cursor: pointer;
        }
    }
}
::v-deep .el-dialog__body {
    padding: 20px 30px;
    border-bottom: 1px solid #f3f8ff;
    .qs-select-radio-wrapper {
        width: 100%;
        max-height: 150px;
        overflow-y: auto;
        .el-radio {
            display: block;
            margin-right: 0;
            padding: 16px 0;
            .el-radio__inner {
                background: #f3f8ff;
            }
            .el-radio__input {
                &.is-checked {
                    & .el-radio__inner {
                        background: #6874f2;
                    }
                    & + .el-radio__label {
                        color: #6874f2;
                    }
                }
            }
        }
    }
}

.qs-btns-wrapper {
    text-align: center;
    .el-button + .el-button {
        margin-left: 35px;
    }
    .qs-btn-confirm {
        color: #fff;
        font-size: 14px;
        border: none;
    }
    .qs-btn-cancel {
        background: #f4f8ff;
        color: #666666;
        font-size: 14px;
        border: none;
    }
}
::v-deep
    .el-dialog__body
    .qs-select-radio-wrapper
    .el-radio
    .el-radio__input.is-checked
    + .el-radio__label {
    color: #105fff;
}
::v-deep
    .el-dialog__body
    .qs-select-radio-wrapper
    .el-radio
    .el-radio__input.is-checked
    .el-radio__inner {
    background: #105fff;
}
</style>

<template>
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="dialogAuthVisible" width="567px">
        <div>
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-form-item label="选择角色" prop="role_id">
                    <el-select v-model="form.role_id" :clearable="true" placeholder="请选择选择角色">
                        <el-option v-for="(item, index) in roleList" :label="item.role_name" :value="item.role_id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button size="medium" @click="dialogAuthVisible = false">取 消</el-button>
            <el-button size="medium" class="qs-search-btn qs-btn-linear-gradient" type="primary" :disabled="loading" @click="onSubmit('form')">确 定</el-button>
            <inviteMemberStep2 ref="refInviteMemberStep2"></inviteMemberStep2>
        </div>
    </el-dialog>
</template>

<script>
import { getAllRole } from '@/api/setting/index'
import { createInviteLink } from '@/api/user/index'
import inviteMemberStep2 from '@/components/inviteMemberStep2.vue'
export default {
    name: 'talentClassifyAllTalentSetting',
    components: {
        inviteMemberStep2,
    },
    data() {
        return {
            dialogAuthVisible: false,
            form: {
                role_id: '',
            },
            rules: {
                role_id: [
                    {
                        required: true,
                        message: '请输入用户角色',
                        trigger: 'change',
                    },
                ],
            },
            roleList: [],
            title: '选择角色',
            loading: false,
        }
    },
    mounted() {},
    methods: {
        handleOpen() {
            this.initRoleList()
            this.dialogAuthVisible = true
        },
        initRoleList() {
            getAllRole()
                .then((res) => {
                    if (res.code == '0000') {
                        this.roleList = res.data
                    }
                })
                .catch(() => {})
        },
        onSubmit(formName) {
            this.loading = true
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    createInviteLink({
                        role_id: this.form.role_id,
                    })
                        .then((res) => {
                            if (res.code == '0000') {
                                this.$message.success(res.msg)
                                this.loading = false
                                this.dialogAuthVisible = false
                                this.form.role_id = ''
                                this.$refs.refInviteMemberStep2.handleOpen(
                                    res.data
                                )
                            }
                        })
                        .catch(() => {
                            this.loading = false
                        })
                } else {
                    this.loading = false
                    return false
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.dialog-footer {
    text-align: center;
}
::v-deep .el-input {
    width: 90%;
}
::v-deep .el-select {
    width: 100%;
}
</style>

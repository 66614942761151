const state = {
    // 是否有左侧菜单栏
    isSideBar:true,
    // 是否可以缩放
    isZoom:true,
    // 当前选中头部菜单id
    rootMenuId:0
}
const mutations = {
    SET_ISSIDEBAR (state,data){
        state.isSideBar = data
    },
    SET_ISZOOM (state,data){
        state.isZoom = data
    },
    SET_ROOTMENUID(state,data){
        state.rootMenuId = data
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-03-09 13:51:44
 * @LastEditors: pj
 * @LastEditTime: 2023-04-25 09:22:36
 */
import vue from 'vue'
// 获取分类
function getClassify (data) {
    return vue.$get('/saas/api/publics/getClassify', data)
}
//获取标签列表 - 人才
function getTagList (data) {
    return vue.$get('/saas/api/publics/getTagList', data)
}
//获取联系人列表 
function getContactList (data) {
    return vue.$get('/saas/api/publics/getContactList', data)
}
// 面试邀请 
function doInterview (data) {
    return vue.$post('/saas/api/interview/invite', data)
}
//面试结果 
function interviewResult (data) {
    return vue.$post('/saas/api/process/interviewResult', data)
}
//设置提醒 
function setRemind (data) {
    return vue.$post('/saas/api/interview/setRemind', data)
}
//获取提醒详情 
function getRemindInfo (data) {
    return vue.$get('/saas/api/interview/getRemindInfo', data)
}
// 修改提醒
function editRemind (data) {
    return vue.$post('/saas/api/interview/editRemind', data)
}
//获取操作权限
function getOperateAuthList (data) {
    return vue.$get('/saas/api/publics/getOperateAuth', data)
}
//修改账号信息
function changeAccountInfo (data) {
    return vue.$post('/saas/api/user/changeAccountInfo', data)
}
//创建邀请链接 
function createInviteLink (data) {
    return vue.$post('/saas/api/user/createInviteLink', data)
}
//左侧统计栏 
function getLeftMenuStat (data) {
    return vue.$get('/saas/api/publics/getLeftMenuStat', data)
}
//获取提醒通知  
function getRemindNotice (data) {
    return vue.$get('/saas/api/publics/getRemindNotice', data)
}
// 获取提醒通知列表 
function getRemindRecordList (data) {
    return vue.$get('/saas/api/publics/getRemindRecordList', data)
}
export {
    getClassify,
    getTagList,
    getContactList,
    doInterview,
    setRemind,
    interviewResult,
    getOperateAuthList,
    changeAccountInfo,
    createInviteLink,
    getRemindInfo,
    editRemind,
    getLeftMenuStat,
    getRemindNotice,
    getRemindRecordList
}

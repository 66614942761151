/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-02-27 09:04:55
 * @LastEditors: pj
 * @LastEditTime: 2023-05-11 16:24:54
 */
import md5 from "js-md5";
var sign_key = "74ats"
// 忽略大小写
function sortCharter (a, b) {
    if (a.toString().toUpperCase() > b.toString().toUpperCase()) {
        return 1;
    } else if (a.toString().toUpperCase() == b.toString().toUpperCase()) {
        return 0;
    } else {
        return -1;
    }
}
// 生成sing
function createSing (params) {
    var str = ''
    for (const key in params) {
        if (typeof (params[key]) == 'array' || typeof (params[key]) == 'object') {//数组格式排除
            continue
        }
        if (params[key] == undefined) {//数组格式排除
            continue
        }
        if (typeof (params[key]) == 'string') {//字符串去除前后空格
            params[key] = params[key].replace(/^\s+|\s+$/g, "")
        }
        if (key == 'file') {//上传文件 不拼接
            str = ''
        } else {
            str += `&${key}=${params[key]}`
        }
    }
    str = str.slice(1)
    // // console.log(str, 'str')
    var md5Str = md5(str)
    delete params.sign_key
    return md5Str
}
function objKeySort (data) {
    let objNew = {
        ...data
    }
    objNew.sign_key = sign_key
    objNew = objNew || {};
    let arr = [];
    for (var key in objNew) {
        arr.push(key)
    }
    arr.sort();
    let str = {}
    if (arr.length > 0) {
        for (var i in arr) {
            str[arr[i]] = objNew[arr[i]]
        }
        // str = str.substr(0, str.length - 1)
    }
    return createSing(str)
}
// 排序
// function objKeySort (obj) { //排序的函数
//     let objNew = {
//         ...obj
//     }
//     objNew.sign_key = sign_key
//     var newkey = Object.keys(objNew).sort(sortCharter);
//     //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
//     var newObj = {}; //创建一个新的对象，用于存放排好序的键值对
//     for (var i = 0; i < newkey.length; i++) { //遍历newkey数组
//         newObj[newkey[i]] = objNew[newkey[i]]; //向新创建的对象中按照排好的顺序依次增加键值对
//     }
//     // console.log(newObj)
//     return createSing(newObj)
//     // return newObj;//返回排好序的新对象
// }

export default objKeySort

<template>
    <div class="qs-header-menu-wrapper">
        <Logo></Logo>
        <TopMenu :parentMenu="parentMenu"></TopMenu>
        <UserInfo></UserInfo>
    </div>
</template>

<script>
import Logo from '@/layout/components/menu/logo.vue'
import TopMenu from '@/layout/components/menu/topMenu.vue'
import UserInfo from '@/layout/components/menu/userInfo.vue'
export default {
    name: 'headermenu',
    props: ['parentMenu'],
    components: {
        Logo,
        TopMenu,
        UserInfo,
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
.qs-header-menu-wrapper {
    padding-left: 235px;
    height: 60px;
    position: relative;
}
</style>
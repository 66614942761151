<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-04-11 14:33:30
 * @LastEditors: pj
 * @LastEditTime: 2023-04-20 15:06:08
-->
<template>
    <el-dialog :close-on-click-modal="false" append-to-body :visible.sync="isWechatDialog" width="470px" center :show-close="false" top="30vh">
        <div slot="title" class="qs-select-company-title">
            扫描二维码绑定微信
            <i class="qs-title-close el-icon-close" @click="handleClose()"></i>
        </div>
        <div class="qs-erweima"><img :src="qrcodeUrl" alt="" /></div>
    </el-dialog>
</template>

<script>
import { getWechatBindQrcode } from '@/api/user/index'
export default {
    data() {
        return {
            isWechatDialog: false,
            qrcodeUrl: '',
        }
    },
    methods: {
        getWechatQrcode() {
            getWechatBindQrcode()
                .then((res) => {
                    if (res.code == '0000') {
                        this.qrcodeUrl = res.data
                        this.isWechatDialog = true
                    }
                })
                .catch(() => {})
        },
        // 关闭页面
        handleClose() {
            this.$emit('updateUserinfo')
            this.isWechatDialog = false
        },
    },
}
</script>

<style lang="scss" scoped>
.qs-select-company-title {
    text-align: left;
    position: relative;
    color: #333333;
    font-size: 18px;
    font-weight: 800;
    .qs-title-close {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        color: #999999;
        font-size: 24px;
        cursor: pointer;
    }
}
.qs-erweima {
    width: 295px;
    height: 295px;
    margin: 0 auto;
    img {
        display: block;
        width: 100%;
        height: 100%;
    }
}
</style>

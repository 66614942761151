import layout from '@/layout/index.vue'
const job = {
	path: '/job',
	component: layout,
	name: 'job',
	redirect: '/job/classify',
	meta: {
		title: '职位',
		isSideBar: true
	},
	children: [{
			path: '/job/classify',
			component: () => import('@/views/job/classify/index.vue'),
			name: 'jobClassify',
			redirect: '/job/classify/allJob',
			meta: {
				title: '职位分类'
			},
			children: [{
					path: '/job/classify/allJob',
					component: () => import('@/views/job/classify/allJob/index.vue'),
					name: 'jobClassifyAllJob',
					redirect: '/job/classify/allJob/list',
					meta: {
						title: '所有职位'
					},
					children: [{
							path: '/job/classify/allJob/list',
							component: () => import('@/views/job/classify/allJob/list.vue'),
							name: 'jobClassifyAllJobList',
							meta: {
								title: '所有职位',
								isShow: true,
								activeMenu: '/job/classify/allJob'
							},
						},
						{
							path: '/job/classify/allJob/add',
							component: () => import('@/views/job/classify/allJob/add.vue'),
							name: 'jobClassifyAllJobAdd',
							meta: {
								title: '新增职位',
								isShow: true,
								activeMenu: '/job/classify/allJob'
							},
						},
						{
							path: '/job/classify/allJob/edit',
							component: () => import('@/views/job/classify/allJob/edit.vue'),
							name: 'jobClassifyAllJobEdit',
							meta: {
								title: '编辑职位',
								isShow: true,
								activeMenu: '/job/classify/allJob'
							},
						},
					]
				},
				{
					path: '/job/classify/browseJob',
					component: () => import('@/views/job/classify/browseJob/index.vue'),
					name: 'jobClassifyBrowseJob',
					redirect: '/job/classify/browseJob/list',
					meta: {
						title: '最近浏览职位'
					},
					children: [{
						path: '/job/classify/browseJob/list',
						component: () => import('@/views/job/classify/browseJob/list.vue'),
						name: 'jobClassifybrowseJobList',
						meta: {
							title: '最近浏览职位',
							isShow: true,
							activeMenu: '/job/classify/browseJob'
						},
					}]
				},
				{
					path: '/job/classify/inProgressJob',
					component: () => import('@/views/job/classify/inProgressJob/index.vue'),
					name: 'jobClassifyInProgressJob',
					redirect: '/job/classify/inProgressJob/list',
					meta: {
						title: '进展中的职位'
					},
					children: [{
						path: '/job/classify/inProgressJob/list',
						component: () => import('@/views/job/classify/inProgressJob/list.vue'),
						name: 'jobClassifyInProgressJobList',
						meta: {
							title: '进展中的职位',
							isShow: true,
							activeMenu: '/job/classify/inProgressJob'
						},
					}]
				},
				{
					path: '/job/classify/attentionJob',
					component: () => import('@/views/job/classify/attentionJob/index.vue'),
					name: 'jobClassifyAttentionJob',
					redirect: '/job/classify/attentionJob/list',
					meta: {
						title: '关注的职位'
					},
					children: [{
						path: '/job/classify/attentionJob/list',
						component: () => import('@/views/job/classify/attentionJob/list.vue'),
						name: 'jobClassifyAttentionJobList',
						meta: {
							title: '关注的职位',
							isShow: true,
							activeMenu: '/job/classify/attentionJob'
						},
					}]
				},
				{
					path: '/job/classify/successJob',
					component: () => import('@/views/job/classify/successJob/index.vue'),
					name: 'jobClassifySuccessJob',
					redirect: '/job/classify/successJob/list',
					meta: {
						title: '成功的职位'
					},
					children: [{
						path: '/job/classify/successJob/list',
						component: () => import('@/views/job/classify/successJob/list.vue'),
						name: 'jobClassifySuccessJobList',
						meta: {
							title: '成功的职位',
							isShow: true,
							activeMenu: '/job/classify/successJob'
						},
					}]
				},
				{
					path: '/job/classify/cancelJob',
					component: () => import('@/views/job/classify/cancelJob/index.vue'),
					name: 'jobClassifyCancelJob',
					redirect: '/job/classify/cancelJob/list',
					meta: {
						title: '取消的职位'
					},
					children: [{
						path: '/job/classify/cancelJob/list',
						component: () => import('@/views/job/classify/cancelJob/list.vue'),
						name: 'jobClassifyCancelJobList',
						meta: {
							title: '取消的职位',
							isShow: true,
							activeMenu: '/job/classify/cancelJob'
						},
					}]
				}
			]
		},
		{
			path: '/job/favorites',
			component: () => import('@/views/job/favorites/index.vue'),
			name: 'jobFavorites',
			meta: {
				title: '职位收藏夹'
			},
			children: [{
				path: '/job/favorites/jobPool/:type',
				component: () => import('@/views/job/favorites/jobPool/index.vue'),
				name: 'jobFavoritesTalentPool',
				meta: {
					title: ''
				},
			}]
		},
		{
			path: '/job/recycle',
			component: () => import('@/views/job/recycle/index.vue'),
			name: 'jobRecycle',
			meta: {
				title: '回收站'
			},
			children: [{
				path: '/job/recycle/recycleList',
				component: () => import('@/views/job/recycle/recycleList/index.vue'),
				name: 'jobRecycleTalent',
				redirect: '/job/recycle/recycleList/list',
				meta: {
					title: '职位回收站'
				},
				children: [{
					path: '/job/recycle/recycleList/list',
					component: () => import('@/views/job/recycle/recycleList/list.vue'),
					name: 'jobRecycleTalentList',
					meta: {
						title: '职位回收站',
						isShow: true,
						activeMenu: '/job/recycle/recycleList'
					},
				}]
			}, ]
		}
	]
}
export default job

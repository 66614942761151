const throttle = {
    data () {
        return {
            isor: true
        }
    },
    methods: {
        // _debounce (func, delay, immediate) {
        //     let timer;
        //     let context = this;
        //     if (timer) clearTimeout(timer);
        //     if (immediate) {
        //         // console.log(immediate)
        //         // 立即执行 -- 节流：在单位时间内频繁触发事件，只有第一次生效
        //         let callNow = !timer;
        //         timer = setTimeout(() => {
        //             timer = null;
        //         }, delay)
        //         if (callNow) func.apply(context, arguments);
        //         return
        //     } else {
        //         // 非立即执行 -- 防抖：一段时间内连续触发事件，只执行最后一次
        //         timer = setTimeout(() => {
        //             func.apply(context, arguments);
        //         }, delay)
        //         return
        //     }
        // },
        _debounce (func, delay) {
            var setTimeOut
            if (!this.isor) {
                clearTimeout(setTimeOut)
            } else {
                this.isor = false
                setTimeOut = setTimeout(() => {
                    typeof func === "function" ? func() : ""
                    this.isor = true
                }, delay)
                return
            }
        }

    }
}
export default throttle

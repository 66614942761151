<template>
	<el-dialog :close-on-click-modal="false" append-to-body :visible.sync="isFavoritesDialog" width="420px" center
		:show-close="true" top="30vh" @close="handleClose">
		<div slot="title">{{ title }}</div>
		<div class="qs-floder-list" v-loading="loading">
			<div :class="item.select ? 'qs-floder-item qs-floder-item-active' : 'qs-floder-item'"
				v-for="(item, index) in favoritesList" :key="index" @mouseleave="floderOpration(item, 'reset')">
				<div class="qs-floder-input qs-floder-input-edit" v-if="item.edit">
					<el-input v-model="favorites_name_edit" class="qs-input" type="text" @focus="resetFavorite">
					</el-input>
					<div class="qs-cancel-btn" @click="floderOpration(item, 'deleteValLine')"><img class="img"
							src="@/assets/images/talent/cancel-icon.png" alt="" /></div>
					<div class="qs-sure-btn" @click="floderOpration(item, 'editLine')"><img class="img"
							src="@/assets/images/talent/sure-icon.png" alt="" /></div>
				</div>
				<span class="qs-floder-item-text" v-if="(!item.edit && talent_id != '') || (!item.edit && job_id != '')"
					@click="floderOpration(item, 'select')" @mouseover="floderOpration(item, 'hover')">
					{{ item.favorites_name }}
				</span>
				<span class="qs-floder-item-text" v-if="!item.edit && talent_id == '' && job_id == ''"
					@mouseover="floderOpration(item, 'hover')">{{ item.favorites_name }}</span>
				<div class="qs-hover-floder" v-if="item.hover&&ifShowFavoriteOprate=='editFavorite'">
					<div class="qs-hover-floder-text" @click="floderOpration(item, 'edit')">编辑收藏夹</div>
					<div class="qs-hover-floder-text" @click="floderOpration(item, 'delete')">删除收藏夹</div>
				</div>
			</div>
		</div>
		<div class="qs-floder-input" v-if="showAddFloder">
			<el-input v-model="favorites_name" class="qs-input" type="text"></el-input>
			<div class="qs-cancel-btn" @click="floderOpration('', 'deleteVal')"><img class="img"
					src="@/assets/images/talent/cancel-icon.png" alt="" /></div>
			<div class="qs-sure-btn" @click="floderOpration('', 'add')"><img class="img"
					src="@/assets/images/talent/sure-icon.png" alt="" /></div>
		</div>
		<div class="qs-floder-line"></div>
		<div class="qs-floder-btn" v-if="ifShowFavoriteOprate=='editFavorite'">
			<el-button @click="floderOpration('', 'show')" class="add-floder-btn" type="default">增加文件夹</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import {
		talentFavoritesList,
		talentFavoritesAdd,
		talentFavoritesEdit,
		talentFavoritesDelete,
		joinFavorites,
	} from '@/api/talent/index'
	import {
		jobFavoritesList,
		jobFavoritesAdd,
		jobFavoritesEdit,
		jobFavoritesDelete,
		joinJobFavorites,
	} from '@/api/job/index'
	import {
		mapActions
	} from 'vuex'
	export default {
		props: {
			title: {
				default: '',
			},
		},
		data() {
			return {
				isFavoritesDialog: false,
				showAddFloder: false,
				favorites_name: '',
				favorites_name_edit: '',
				favoritesList: [],
				loading: true,
				talent_id: '',
				talent_name: '',
				job_id: '',
				job_name: '',
				item: {},
				favoritesType: '',
				ifShowFavoriteOprate: ''
			}
		},
		methods: {
			...mapActions({
				GenerateRoutes: 'routingTable/GenerateRoutes',
				getMenuList: 'routingTable/getMenuList',
			}),
			updateMenuList() {
				//更新路由
				this.getMenuList().then((response) => {
					if (response.code == '0000') {
						this.GenerateRoutes(response.data).then((res) => {
							let routersList =
								this.$store.state.routingTable.addRouters
							this.$router.addRoutes(routersList)
						})
					}
				})
			},
			floderOpration(item, type) {
				switch (type) {
					case 'add':
						this.addFavorite()
						break
					case 'show':
						this.showAddFloder = true
						break
					case 'deleteVal':
						this.favorites_name = ''
						break
					case 'deleteValLine':
						this.favorites_name_edit = ''
						break
					case 'select':
						this.selectFavorite(item)
						break
					case 'hover':
						this.hoverFavorite(item)
						break
					case 'edit':
						this.editFavorite(item)
						break
					case 'editLine':
						this.item = item
						this._debounce(this.editFavoriteReal(item), 800)
						break
					case 'delete':
						this.deleteFavorite(item)
						break
					case 'reset':
						this.resetFavorite(item)
						break
					default:
						break
				}
			},
			resetFavorite(item) {
				this.favoritesList.forEach((i) => {
					i.hover = false
				})
				this.$forceUpdate()
			},
			editFavoriteReal(item) {
				if (this.favoritesType == 'telentFavorites') {
					talentFavoritesEdit({
						favorites_id: item.favorites_id,
						favorites_name: this.favorites_name_edit,
					}).then((res) => {
						if (res.code == '0000') {
							this.$message.success(res.msg)
							this.getFavoritesList()
							this.updateMenuList()
						}
					})
				} else if (this.favoritesType == 'jobFavorites') {
					jobFavoritesEdit({
						favorites_id: item.favorites_id,
						favorites_name: this.favorites_name_edit,
					}).then((res) => {
						if (res.code == '0000') {
							this.$message.success(res.msg)
							this.getFavoritesList()
							this.updateMenuList()
						}
					})
				}
			},
			editFavorite(item) {
				this.favoritesList.forEach((i) => {
					i.edit = false
					if (item.favorites_id == i.favorites_id) {
						i.edit = true
						this.favorites_name_edit = item.favorites_name
					}
				})
				this.$forceUpdate()
			},
			deleteFavorite(item) {
				this.$confirm(
						'确定将 ' + item.favorites_name + ' 收藏夹删除吗？',
						'提示', {
							confirmButtonText: '确定',
							confirmButtonClass: 'confirmbtn', //自定义class
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						if (this.favoritesType == 'telentFavorites') {
							talentFavoritesDelete({
								favorites_id: item.favorites_id,
							}).then((res) => {
								if (res.code == '0000') {
									this.$message.success(res.msg)
									this.getFavoritesList()
									this.updateMenuList()
								}
							})
						} else if (this.favoritesType == 'jobFavorites') {
							jobFavoritesDelete({
								favorites_id: item.favorites_id,
							}).then((res) => {
								if (res.code == '0000') {
									this.$message.success(res.msg)
									this.getFavoritesList()
									this.updateMenuList()
								}
							})
						}
					})
					.catch(() => {})
			},
			hoverFavorite(item) {
				this.favoritesList.forEach((i) => {
					i.hover = false
					if (item.favorites_id == i.favorites_id) {
						i.hover = true
					}
				})
				this.$forceUpdate()
			},
			selectFavorite(item) {
				this.favoritesList.forEach((i) => {
					i.select = false
					if (item.favorites_id == i.favorites_id) {
						i.select = true
					}
				})
				this.$forceUpdate()
				if (this.favoritesType == 'telentFavorites') {
					let warnTxt =
						`确定将<span class="nameWarn">${this.talent_name}</span>加入到<span class="oprateWarn">${item.favorites_name}</span>收藏夹吗？`
					this.$confirm(warnTxt, '提示', {
							confirmButtonText: '确定',
							confirmButtonClass: 'confirmbtn', //自定义class
							cancelButtonText: '取消',
							type: 'warning',
							dangerouslyUseHTMLString: true,
						})
						.then(() => {
							joinFavorites({
								talent_id: this.talent_id,
								favorites_id: item.favorites_id,
							}).then((res) => {
								if (res.code == '0000') {
									this.$message.success(res.msg)
									this.$emit('updateList')
									setTimeout(() => {
										this.updateMenuList()
									}, 200)
									this.handleClose()
								}
							})
						})
						.catch(() => {
							this.favoritesList.forEach((i) => {
								i.select = false
							})
							this.$forceUpdate()
						})
				} else if (this.favoritesType == 'jobFavorites') {
					let warnTxt =
						`确定将<span class='nameWarn'>${this.job_name}</span>加入到<span class='nameWarn'>${item.favorites_name}</span>收藏夹吗？`
					this.$confirm(warnTxt, '提示', {
							confirmButtonText: '确定',
							confirmButtonClass: 'confirmbtn', //自定义class
							cancelButtonText: '取消',
							type: 'warning',
							dangerouslyUseHTMLString: true,
						})
						.then(() => {
							joinJobFavorites({
								job_id: this.job_id,
								favorites_id: item.favorites_id,
							}).then((res) => {
								if (res.code == '0000') {
									this.$message.success(res.msg)
									this.$emit('updateList')
									setTimeout(() => {
										this.updateMenuList()
									}, 200)
									this.handleClose()
								}
							})
						})
						.catch(() => {
							this.favoritesList.forEach((i) => {
								i.select = false
							})
							this.$forceUpdate()
						})
				}
			},
			addFavorite() {
				if (this.favoritesType == 'telentFavorites') {
					talentFavoritesAdd({
						favorites_name: this.favorites_name,
					}).then((res) => {
						if (res.code == '0000') {
							this.$message.success(res.msg)
							this.favorites_name = ''
							this.showAddFloder = false
							this.getFavoritesList()
							this.updateMenuList()
						}
					})
				} else if (this.favoritesType == 'jobFavorites') {
					jobFavoritesAdd({
						favorites_name: this.favorites_name
					}).then(
						(res) => {
							if (res.code == '0000') {
								this.$message.success(res.msg)
								this.favorites_name = ''
								this.showAddFloder = false
								this.getFavoritesList()
								this.updateMenuList()
							}
						}
					)
				}
			},
			//获取收藏夹列表
			getFavoritesList() {
				if (this.favoritesType == 'telentFavorites') {
					talentFavoritesList()
						.then((res) => {
							if (res.code == '0000') {
								this.favoritesList =
									res.data == null ? [] : res.data
								if (this.favoritesList.length > 0) {
									this.favoritesList.forEach((item) => {
										item.select = false
										item.hover = false
										item.edit = false
									})
								}
								this.loading = false
								this.isFavoritesDialog = true
								if (this.favoritesList.length == 0) {
									this.showAddFloder = true
								}
							}
						})
						.catch((err) => {
							// console.log(err)
						})
				} else if (this.favoritesType == 'jobFavorites') {
					jobFavoritesList()
						.then((res) => {
							if (res.code == '0000') {
								this.favoritesList =
									res.data == null ? [] : res.data
								if (this.favoritesList.length > 0) {
									this.favoritesList.forEach((item) => {
										item.select = false
										item.hover = false
										item.edit = false
									})
								}
								this.loading = false
								this.isFavoritesDialog = true
								if (this.favoritesList.length == 0) {
									this.showAddFloder = true
								}
							}
						})
						.catch((err) => {
							// console.log(err)
						})
				}
			},
			initData(val, row) {
				this.val = val
				this.row = row
			},
			// 打开弹窗
			handleOpen(val, pageOrigin, row) {
				this.ifShowFavoriteOprate = val
				this.favoritesType = pageOrigin
				switch (pageOrigin) {
					case 'telentFavorites':
						if (val == 'one') {
							this.talent_id = row.talent_id
							this.talent_name = row.talent_name
						} else if (val == 'mul') {
							let select_id_arr = []
							let select_name_arr = []
							row.forEach((item) => {
								select_id_arr.push(item.talent_id)
								select_name_arr.push(item.talent_name)
							})
							this.talent_id = select_id_arr.join(',')
							this.talent_name = select_name_arr.join(',')
						} else if (val == 'editFavorite') {
							this.talent_id = ''
							this.job_id = ''
						}
						this.getFavoritesList()
						break
					case 'jobFavorites':
						if (val == 'one') {
							this.job_id = row.job_id
							this.job_name = row.job_name
						} else if (val == 'mul') {
							let select_id_arr = []
							let select_name_arr = []
							row.forEach((item) => {
								select_id_arr.push(item.job_id)
								select_name_arr.push(item.job_name)
							})
							this.job_id = select_id_arr.join(',')
							this.job_name = select_name_arr.join(',')
						} else if (val == 'editFavorite') {
							this.job_id = ''
						}
						this.getFavoritesList()
						break
					default:
						break
				}
			},
			// 关闭页面
			handleClose() {
				this.favorites_name = ''
				this.isFavoritesDialog = false
				if (this.$route.name == 'talentFavoritesTalentPool') {
					this.$router.push('/talent')
				} else if (this.$route.name == 'jobFavoritesTalentPool') {
					this.$router.push('/job')
				}
			},
		},
	}
</script>

<style lang="scss" scoped>
	.qs-floder-list {
		padding: 0 35px;

		.qs-floder-item {
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #333333;
			margin-bottom: 10px;
			background: url('@/assets/images/talent/packup-icon.png') 16px center no-repeat;
			background-size: 7px 14px;
			padding-left: 30px;
			position: relative;
			cursor: pointer;

			.qs-floder-input-edit {
				padding-left: 0;
			}

			.qs-hover-floder {
				position: absolute;
				top: 80%;
				left: 74px;
				width: 122px;
				background: #ffffff;
				box-shadow: 0px 0px 5px 1px rgba(220, 220, 220, 0.35);
				border-radius: 6px;
				font-size: 14px;
				color: #333;
				z-index: 9;
				padding: 20px 0 0;

				.qs-hover-floder-text {
					text-align: center;
					margin-bottom: 20px;

					&:hover {
						color: #6874f2;
					}
				}
			}

			&:last-child {
				margin-bottom: 10px;
			}

			&:last-child::after {
				display: none;
			}

			&:before {
				display: block;
				content: '';
				width: 16px;
				height: 1px;
				border-bottom: 1px dashed #e3eef6;
				position: absolute;
				left: 0;
				top: 50%;
			}

			&:after {
				display: block;
				content: '';
				width: 1px;
				height: 50px;
				border-left: 1px dashed #e3eef6;
				position: absolute;
				left: 0;
				top: 10px;
			}

			&.qs-floder-item-active {
				.qs-floder-item-text {
					border-radius: 6px;
					color: #6874f2;
					background: #f9fcff url('@/assets/images/talent/floder-icon.png') left center no-repeat;
					padding: 8px 19px 8px 29px;
				}
			}

			.qs-floder-item-text {
				display: block;
				padding: 8px 19px 8px 29px;
				background: url('@/assets/images/talent/floder-icon.png') left center no-repeat;
				background-size: 19px 15px;
			}
		}
	}

	.qs-floder-input {
		padding: 0 35px;
		position: relative;

		.qs-input {
			::v-deep .el-input__inner {
				border: 1px solid #e3eef6;
			}
		}

		.qs-cancel-btn {
			width: 10px;
			height: 10px;
			position: absolute;
			top: 15px;
			right: 85px;
			cursor: pointer;

			.img {
				display: block;
				width: 100%;
				height: 100%;
			}
		}

		.qs-sure-btn {
			width: 17px;
			height: 12px;
			position: absolute;
			top: 14px;
			right: 46px;
			cursor: pointer;

			.img {
				display: block;
				width: 100%;
				height: 100%;
			}
		}
	}

	.qs-floder-line {
		width: 100%;
		height: 1px;
		background: #f3f8ff;
		margin: 25px 0 15px;
	}

	.qs-floder-btn {
		padding-left: 35px;

		.add-floder-btn {
			padding: 9px 16px;
			border: none;
			background: #105fff;
			color: #fff;
		}
	}

	::v-deep .el-dialog--center .el-dialog__body {
		padding: 25px 0 30px;
	}

	::v-deep .el-dialog__header {
		text-align: left;
	}
</style>

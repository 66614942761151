import Vue from 'vue'
import Vuex from 'vuex'
import home from './modules/home'
import interview from './modules/interview'
import job from './modules/job'
import process from './modules/process'
import talent from './modules/talent'
import wholeNetwork from './modules/wholeNetwork'
import routingTable from './modules/routingTable'
import user from './modules/user'
import common from './modules/common'
import layout from './modules/layout'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    home,
    interview,
    job,
    process,
    talent,
    wholeNetwork,
    routingTable,
    user,
	common,
    layout
  }
})

/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-02-28 18:02:55
 * @LastEditors: pj
 * @LastEditTime: 2023-04-25 10:44:44
 */
import { getToken } from '@/utils/auth'
import Axiso from 'axios'
import qs from 'qs'
const service = Axiso.create({
    baseURL: 'https://ats.gaoning.wang/'
})
// 请求列表
const pendingRequest = new Map()
function generateReqKey (config) {
    const { method, url, params, data } = config
    return [method, url, qs.stringify(params), qs.stringify(data)].join(
        '&'
    )
}

function addPendingRequest (config) {
    const requestKey = generateReqKey(config)
    config.cancelToken =
        config.cancelToken ||
        new Axiso.CancelToken((cancel) => {
            if (!pendingRequest.has(requestKey)) {
                pendingRequest.set(requestKey, cancel)
            }
        })
}

function removePendingRequest (config) {
    const requestKey = generateReqKey(config)

    if (pendingRequest.has(requestKey)) {
        const cancel = pendingRequest.get(requestKey)
        cancel(requestKey)
        pendingRequest.delete(requestKey)
    }
}

service.interceptors.request.use(
    config => {
        // if (token) {
        config.headers['token'] = getToken()
        // }

        removePendingRequest(config) // 检查是否存在重复请求，若存在则取消已发的请求

        addPendingRequest(config) // 把当前请求添加到pendingRequest对象中

        return config
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    (response) => {
        removePendingRequest(response.config) // 从pendingRequest对象中移除请求
        return response
    },
    (error) => {
        removePendingRequest(error.config || {}) // 从pendingRequest对象中移除请求
        if (Axiso.isCancel(error)) {
            // // console.log('已取消的重复请求：' + error.message)
        } else {
            // 添加异常处理
        }
        return Promise.reject(error)
    }
)

export default service

import {
	constantRoutes
} from "@/router/index";
import home from "@/router/modules/home";
import interview from "@/router/modules/interview";
import job from "@/router/modules/job";
import process from "@/router/modules/process";
import talent from "@/router/modules/talent";
import wholeNetwork from "@/router/modules/wholeNetwork";
import setting from "@/router/modules/setting";
import {
	menuList
} from "@/api/user/index";
import {
	getLeftMenuStat
} from "@/api/public/index";
const state = {
	routers: [],
	addRouters: [],
	route_talent_num: {}, //左侧人才统计数
	route_job_num: {}, //左侧职位统计数
	route_process_num: {}, //左侧进程统计数
};
const mutations = {
	SET_ROUTERS: (state, routers) => {
		state.addRouters = routers;
		state.routers = constantRoutes.concat(routers);
	},
	SET_ROUTE_TALENT_NUM: (state, data) => {
		state.route_talent_num = data;
	},
	SET_ROUTE_JOB_NUM: (state, data) => {
		state.route_job_num = data;
	},
	SET_ROUTE_PROCESS_NUM: (state, data) => {
		state.route_process_num = data;
	},
};
const actions = {
	GenerateRoutes({
		commit
	}, data) {
		return new Promise((resolve) => {
			let menuList = [{
					...home,
				},
				{
					...talent,
				},
				{
					...job,
				},
				{
					...interview,
				},
				{
					...process,
				},
				{
					...wholeNetwork,
				},
				{
					...setting,
				},
			];
			var newRouterList = [];
			// menulist接口返回的路由信息带权限 routerList前端定义的路由信息
			function addRouteList(menulist, routerList) {
				// console.log(menulist && menulist.length > 0)
				if (menulist && menulist.length > 0) {
					var menuList = [];
					for (let menuIdx = 0; menuIdx < menulist.length; menuIdx++) {
						const menuItem = menulist[menuIdx];
						for (let routerIdx = 0; routerIdx < routerList.length; routerIdx++) {
							const routerItem = routerList[routerIdx];
							var newRouterItem = {
								...routerItem,
							};
							newRouterItem["meta"] = {
								...newRouterItem["meta"],
							};
							if (menuItem["path"] == routerItem["path"]) {
								delete newRouterItem["children"];
								newRouterItem.meta.menu_id = menuItem.menu_id;
								newRouterItem.meta.is_customize = menuItem.is_customize;
								newRouterItem.meta.mark = menuItem.mark; //根据mark更新左侧边栏的数量
								// // console.log(newRouterItem.meta)
								if (newRouterItem.meta.title == "") {
									//处理自定义内容
									var pathAry = newRouterItem.path.split(":");
									newRouterItem.path = pathAry[0] + menuItem.customize_id;
									newRouterItem.meta.title = menuItem.menu_name;
									newRouterItem.meta.favorites_id = menuItem.customize_id; //收藏夹ID
								}
								var menuItem2 = newRouterItem;
								if (
									menuItem["children"] != undefined &&
									menuItem["children"].length > 0 &&
									routerItem["children"] != undefined &&
									routerItem["children"].length > 0
								) {
									var childItem = addRouteList(
										menuItem["children"],
										routerItem["children"]
									);
									menuItem2["children"] = childItem;
								}
								// 四级处理
								if (
									menuItem.level == 3 &&
									routerItem["children"] != undefined &&
									routerItem["children"].length > 0
								) {
									menuItem2["children"] = [...routerItem["children"]];
								}
								menuList.push(menuItem2);
							}
						}
					}
				}else{
					window.location.reload()
				}
				return menuList;
			}
			newRouterList = addRouteList(data, menuList);
			// console.log()
			commit("SET_ROUTERS", newRouterList);
			resolve();
		});
	},
	getMenuList() {
		return menuList();
	},
	//获取左侧菜单统计
	getLeftMenuStatistics({
		commit
	}, params) {
		return new Promise((resolve, reject) => {
			getLeftMenuStat(params)
				.then((result) => {
					if (result.code == "0000") {
						switch (params.mark) {
							case "talent":
								commit("SET_ROUTE_TALENT_NUM", result.data);
								break;
							case "job":
								commit("SET_ROUTE_JOB_NUM", result.data);
								break;
							case "process":
								commit("SET_ROUTE_PROCESS_NUM", result.data);
								break;
							default:
								break;
						}
					}
					resolve(result);
				})
				.catch((err) => {
					// console.log(err);
					reject();
				});
		});
	},
};
export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
<template>
    <el-dialog :close-on-click-modal="false" append-to-body title="" :visible.sync="mobileVisible" center width="464px" :before-close="handleClose">
        <div slot="title">
            <div class="qs-invite-link-title">更换手机号</div>
        </div>
        <el-form label-width="80px" ref="form" :model="form" :rules="rules" auto-complete="on" label-position="left">
            <el-form-item prop="mobile" label="手机号"><el-input placeholder="请输入手机号" v-model="form.mobile"></el-input></el-form-item>
            <el-form-item class="" label="验证码" prop="verify_code">
                <div class="qs-verify-code-box">
                    <el-input class="qs-verify-code-input" placeholder="请输入验证码" v-model="form.verify_code"></el-input>
                    <el-button class="qs-verify-code-btn" v-bind:disabled="dis" @click="getCode">{{ text }}</el-button>
                </div>
            </el-form-item>
            <div class="qs-btn-box">
                <el-button @click="onSubmit('form')" :disabled="mobileChangeStatus" class="el-button--primary qs-btn-linear-gradient" size="medium">确定</el-button>
                <el-button @click="cancel()" class="qs-search-btn-reset" type="info" size="medium">取消</el-button>
            </div>
        </el-form>
    </el-dialog>
</template>

<script>
import { sendSmsCode } from '@/api/user/index'
export default {
    props: ['mobile'],
    data() {
        return {
            mobileVisible: false,
            form: {
                mobile: '',
                verify_code: '',
            },
            rules: {
                mobile: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入手机号',
                    },
                ],
                verify_code: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入验证码',
                    },
                ],
            },
            dis: false,
            show: true,
            isGrey: false,
            timer: null,
            count: '',
            text: '发送验证码',
            mobileChangeStatus: false,
        }
    },
    methods: {
        // 打开弹窗
        handleOpen() {
            this.mobileVisible = true
        },
        // 关闭页面
        handleClose() {
            this.mobileVisible = false
        },
        cancel() {
            this.mobileVisible = false
            this.$emit('updateEditMobile', false)
        },
        //发送验证码
        getCode() {
            if (this.form.mobile == '') {
                this.$message.error('请输入手机号！')
            } else {
                this.sendCode(60)
                let params = {
                    mobile: this.form.mobile,
                    send_type: 'change_mobile',
                }
                sendSmsCode(params).then((res) => {
                    if (res.code == '0000') {
                        this.$message.success('发送验证码成功！')
                    } else if (res.code == '4000') {
                        this.text = '发送验证码'
                        this.dis = false
                        clearInterval(this.timer)
                    }
                })
            }
        },
        sendCode(count) {
            let TIME_COUNT = 60
            if (!this.timer) {
                this.count = count
                this.isGrey = true
                this.show = false
                this.dis = true
                this.text = this.count + 's后重新发送'
                localStorage.setItem('changeMobiletime', this.count)
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        localStorage.setItem('changeMobiletime', this.count)
                        this.text = this.count + 's后重新发送'
                        this.count--
                    } else {
                        this.dis = false
                        this.isGrey = false
                        this.show = true
                        clearInterval(this.timer)
                        this.text = '重新发送'
                        this.timer = null
                    }
                }, 1000)
            }
        },
        onSubmit(formName) {
            this.mobileChangeStatus = true
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.mobileChangeStatus = false
                    this.$emit('changeMobileFun', this.form)
                } else {
                    this.mobileChangeStatus = false
                    return false
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.qs-invite-link-title {
    text-align: left;
    color: #333;
    font-size: 18px;
    font-weight: bold;
    box-sizing: border-box;
    padding-left: 6px;
}
.qs-verify-code-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .qs-verify-code-input {
        width: 60%;
    }
    .qs-verify-code-btn {
        width: 35%;
        background: #e3eef6;
        border-color: #e3eef6;
        color: #666666;
        padding: 12px 0;
    }
}
.qs-btn-box {
    text-align: center;
    border-top: 1px solid #f3f8ff;
    padding-top: 15px;
}
::v-deep .el-dialog--center .el-dialog__body {
    padding-bottom: 15px;
}
</style>

<template>
	<div class="qs-user-wrapper clearfix">
		<div class="qs-user-info">
			{{ name }}（{{ role_name }}）
			<i class="el-icon-caret-bottom"></i>
			<!-- 创始人账号第一次登录引导提示 -->
			<div class="qs-user-dialog" v-if="inviteGuide">
				<div class="qs-invaite-guide">
					<span class="invite-text">邀请成员</span>
					<img class="invite-icon" src="@assets/images/layout/invite-icon.png" alt="" />
				</div>
				<div class="qs-user-menu-list">
					<div class="text" @click="handleClick('accountSetting')">账号设置</div>
					<div class="text" @click="handleClick('userManage')" v-if="is_creator==1">用户管理</div>
					<div class="text" @click="handleClick('invite')">邀请成员</div>
					<div class="text" @click="handleClick('oprateLogs')">操作日志</div>
					<!-- <div class="text" @click="handleClick('bindWechat')" v-if="wechat_bind==0">绑定微信</div> -->
					<!-- <div class="text" @click="handleClick('cancelWechat')" v-if="wechat_bind==1">解绑微信</div> -->
					<div class="text" @click="handleClick('selectCom')" v-if="isShowChangeCom">切换企业</div>
					<div class="text" @click="handleClick('loginOut')">退出登录</div>
				</div>
			</div>
			<!-- 个人中心下拉弹窗 -->
			<div class="qs-user-menulist-system">
				<div class="text" @click="handleClick('accountSetting')">账号设置</div>
				<div class="text" @click="handleClick('userManage')" v-if="is_creator==1">用户管理</div>
				<div class="text" @click="handleClick('invite')">邀请成员</div>
				<div class="text" @click="handleClick('oprateLogs')">操作日志</div>
				<!-- <div class="text" @click="handleClick('bindWechat')" v-if="wechat_bind==0">绑定微信</div> -->
				<!-- <div class="text" @click="handleClick('cancelWechat')" v-if="wechat_bind==1">解绑微信</div> -->
				<div class="text" @click="handleClick('selectCom')" v-if="isShowChangeCom">切换企业</div>
				<div class="text" @click="handleClick('loginOut')">退出登录</div>
			</div>
		</div>
		<!-- 全站搜索 -->
		<!-- <div class="qs-user-serach"></div> -->
		<div class="qs-user-news" @click="handleClick('newsList')"><span v-if="isShowRedCircle"
				class="qs-isShowRedCircle"></span></div>
		<div class="qs-user-add">
			<div class="qs-add-dialog">
				<div class="qs-add-dialog-item" @click="handleClick('addTalent')">新增人才</div>
				<div class="qs-add-dialog-item" @click="handleClick('addJob')">新增职位</div>
			</div>
		</div>
		<!-- 邀请成员 -->
		<inviteMemberStep1 ref="refInviteMemberStep1"></inviteMemberStep1>
		<!-- 切换企业 -->
		<selectCompany ref="refSelectCompany"></selectCompany>
		<!-- 账号设置 -->
		<el-drawer :visible.sync="accountSettingDrawer" size="70%" :show-close="false" :with-header="false"
			:wrapper-closable="true" :before-close="handleClose">
			<accountSetting ref="refAccountSetting" @closeDrawer="closeDrawer"></accountSetting>
		</el-drawer>
		<!-- 操作日志 -->
		<el-drawer :visible.sync="logsDrawer" size="70%" :show-close="false" :with-header="false"
			:wrapper-closable="true" :before-close="handleClose">
			<oprateLogs ref="refOprateLogs" @closeDrawer="closeLogsDrawer"></oprateLogs>
		</el-drawer>
		<!-- 绑定微信 -->
		<bindWechat ref="refBindWechat" @updateUserinfo="updateUserinfo"></bindWechat>
		<!-- 消息通知列表 -->
		<el-drawer append-to-body :visible.sync="remindListDrawer" size="70%" :show-close="false" :with-header="false"
			:wrapper-closable="true" :before-close="handleClose">
			<remindList ref="refRemindList"></remindList>
		</el-drawer>
		<!-- 消息通知 -->
		<systemRemind ref="refSystemRemind" @updateCalendarData="initRemind" @getRemindList="getRemindList">
		</systemRemind>
	</div>
</template>

<script>
	import selectCompany from '@/components/selectCompany.vue'
	import inviteMemberStep1 from '@/components/inviteMemberStep1.vue'
	import accountSetting from '@/components/accountSetting.vue'
	import oprateLogs from '@/components/oprateLogs.vue'
	import bindWechat from '@/components/bindWechat.vue'
	import systemRemind from '@/views/interview/compontents/systemRemind.vue'
	import remindList from '@/components/remindList'
	import {
		mapActions,
		mapMutations,
		mapState
	} from 'vuex'
	import {
		unbindWechat
	} from '@/api/user/index'
	import {
		companyList
	} from '@/api/user'
	import {
		setUserInfo,
		removeToken,
		removeSelectCompanyStateId,
		removeUserInfo,
		removeRequestData,
	} from '@/utils/auth'
	export default {
		components: {
			selectCompany,
			inviteMemberStep1,
			accountSetting,
			oprateLogs,
			bindWechat,
			systemRemind,
			remindList,
		},
		data() {
			return {
				inviteGuide: false,
				accountSettingDrawer: false,
				logsDrawer: false,
				remindListDrawer: false,
				isShowRedCircle: false,
				role_name: localStorage.getItem('role_name'),
				is_creator: 0,
				isShowChangeCom: false,
			}
		},
		computed: {
			...mapState({
				name: (state) => state.user.name,
				wechat_bind: (state) => state.user.wechat_bind,
				userInfo: (state) => state.user.userInfo,
			}),
		},
		mounted() {
			this.getCompanyList()
			if (typeof this.userInfo == 'string') {
				this.is_creator = JSON.parse(this.userInfo).is_creator
			} else {
				this.is_creator = this.userInfo.is_creator
			}
			this.initRemind()
			setInterval(() => {
				const minute = new Date().getMinutes()
				if (minute % 5 == 0) {
					setTimeout(this.initRemind())
				}
			}, 20000)
		},
		methods: {
			...mapMutations({
				SET_USERINFO: 'user/SET_USERINFO',
				SET_WECHAT_BIND: 'user/SET_WECHAT_BIND',
				SET_USERNAME: 'user/SET_USERNAME',
				SET_TOKEN: 'user/SET_TOKEN',
				SET_FIRST_REQUEST: 'common/SET_FIRST_REQUEST',
			}),
			...mapActions({
				loginOut: 'user/loginOut',
				initUserInfo: 'user/initUserInfo',
				initRemindNotice: 'common/initRemindNotice',
			}),
			getCompanyList() {
				companyList()
					.then((res) => {
						if (res.code == '0000') {
							if (res.data.length > 1) {
								this.isShowChangeCom = true
							} else {
								this.isShowChangeCom = false
							}
						}
					})
					.catch((err) => {
						// console.log(err)
					})
			},
			getList() {},
			getRemindList() {
				this.remindListDrawer = true
				setTimeout(() => {
					this.$refs.refRemindList.initData()
				}, 200)
			},
			initRemind() {
				this.initRemindNotice()
					.then((res) => {
						if (res.code == '0000') {
							if (res.data != null) {
								this.isShowRedCircle = true
								// this.isShowRedCircle =
								//     res.data.wait_count > 0 ? true : false
								this.$refs.refSystemRemind.handleOpen(res.data)
							}
							// else {
							// 	this.isShowRedCircle = false
							// }
							// console.log('是否显示新消息提示？', this.isShowRedCircle)
						}
					})
					.catch(() => {})
			},
			updateUserinfo() {
				this.initUserInfo()
			},
			closeDrawer(val) {
				this.accountSettingDrawer = false
			},
			closeLogsDrawer(val) {
				this.logsDrawer = false
			},
			handleLoginOut() {
				this.$confirm('确定退出吗？', '提示', {
						confirmButtonText: '确定',
						confirmButtonClass: 'confirmbtn', //自定义class
						cancelButtonText: '取消',
						type: 'warning',
					})
					.then(() => {
						this.loginOut().then((res) => {
							if (res.code == '0000') {
								removeToken()
								removeSelectCompanyStateId()
								removeUserInfo()
								removeRequestData('wechat_bind')
								removeRequestData('role_name')
								removeRequestData('company_id')
								this.SET_USERINFO({})
								this.SET_WECHAT_BIND('')
								this.SET_USERNAME('')
								this.SET_TOKEN('')
								this.SET_FIRST_REQUEST(true)
								this.$router.push('/login')
							}
						})
					})
					.catch(() => {})
			},
			handleClose() {
				this.accountSettingDrawer = false
				this.logsDrawer = false
				this.remindListDrawer = false
			},
			cancelWechatQrcode() {
				this.$confirm('确定解除微信绑定吗?', '提示', {
						confirmButtonText: '确定',
						confirmButtonClass: 'confirmbtn', //自定义class
						cancelButtonText: '取消',
						type: 'warning',
					})
					.then(() => {
						unbindWechat()
							.then((res) => {
								if (res.code == '0000') {
									this.$message.success(res.msg)
									setUserInfo(JSON.stringify(this.userInfo))
								}
							})
							.catch(() => {})
					})
					.catch(() => {})
			},
			handleClick(eventVal) {
				switch (eventVal) {
					case 'accountSetting':
						//账号设置
						this.accountSettingDrawer = true
						break
					case 'userManage':
						//用户管理
						this.$router.push('/setting/userSetting/allSetting/list')
						break
					case 'invite':
						//邀请成员
						this.$refs.refInviteMemberStep1.handleOpen()
						break
					case 'oprateLogs':
						//操作日志
						this.logsDrawer = true
						setTimeout(() => {
							this.$refs.refOprateLogs.initData()
						}, 200)
						break
					case 'bindWechat':
						//绑定微信
						this.$refs.refBindWechat.getWechatQrcode()
						break
					case 'cancelWechat':
						this.cancelWechatQrcode()
						break
					case 'selectCom':
						//选择企业
						this.$refs.refSelectCompany.handleOpen()
						break
					case 'loginOut':
						//退出系统
						this.handleLoginOut()
						break
					case 'addTalent':
						this.$router.push('/talent/classify/allTalent/add')
						break
					case 'addJob':
						this.$router.push('/job/classify/allJob/add')
						break
					case 'newsList':
						this.remindListDrawer = true
						this.isShowRedCircle = false
						setTimeout(() => {
							this.$refs.refRemindList.initData()
						}, 200)
						break
					default:
						break
				}
			},
		},
	}
</script>

<style lang="scss" scoped>
	.qs-user-wrapper {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 17%;
		padding-right: 32px;

		.qs-user-news {
			width: 30px;
			float: right;
			height: 100%;
			background: url('@assets/images/layout/news.png') no-repeat center center / 16px 16px;
			cursor: pointer;
			margin: 0 24px 0 0;
			position: relative;

			.qs-isShowRedCircle {
				display: block;
				width: 6px;
				height: 6px;
				background: #ff0000;
				border-radius: 50%;
				position: absolute;
				top: 26px;
				right: 3px;
			}
		}

		.qs-user-serach {
			width: 30px;
			float: right;
			height: 100%;
			background: url('@assets/images/layout/search.png') no-repeat center center / 18px 14px;
			cursor: pointer;
			margin: 0 24px 0 26px;
		}

		.qs-log-out {
			float: right;
			height: 100%;
			line-height: 70px;
			text-align: center;
			padding: 0 30px;
			cursor: pointer;
		}

		.qs-user-add {
			width: 30px;
			float: right;
			height: 100%;
			line-height: 70px;
			background: url('@assets/images/layout/add.png') no-repeat center center / 14px 14px;
			cursor: pointer;
			position: relative;
			margin-right: 20px;

			&:hover {
				.qs-add-dialog {
					display: block;
				}
			}

			.qs-add-dialog {
				display: none;
				position: absolute;
				width: 110px;
				background: #ffffff;
				box-shadow: 0px 2px 6px 0px rgba(242, 242, 242, 0.77);
				border-radius: 6px;
				z-index: 9;
				top: 76%;
				right: -32%;
				padding: 20px 0 5px;

				.qs-add-dialog-item {
					height: 30px;
					text-align: center;
					line-height: 30px;
					margin-bottom: 5px;
					font-size: 14px;

					&:hover {
						color: #105fff;
					}
				}
			}
		}

		.qs-user-info {
			height: 100%;
			line-height: 60px;
			cursor: pointer;
			float: right;
			color: $themeTextColor1;
			font-size: 14px;

			&:hover {
				.qs-user-menulist-system {
					display: block;
				}
			}

			.qs-user-menulist-system {
				display: none;
				background: #ffffff;
				position: fixed;
				top: 60px;
				right: 16px;
				z-index: 99;
				width: 100px;
				box-shadow: 0px 0px 5px 1px rgb(220 220 220 / 35%);
				padding: 10px 0 10px;

				.text {
					width: 100%;
					text-align: center;
					margin: 0 auto;
					font-size: 14px;
					color: #333333;
					line-height: 40px;

					&:hover {
						color: #6874f2;
					}
				}
			}

			.qs-user-dialog {
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(0, 0, 0, 0.4);
				z-index: 99;
				display: flex;
				justify-content: right;
				box-sizing: border-box;
				padding: 60px 16px 0 0;

				.qs-invaite-guide {
					display: flex;
					align-items: center;
					justify-content: space-between;
					position: absolute;
					top: 210px;
					right: 131px;

					.invite-text {
						width: 90px;
						text-align: center;
						height: 30px;
						line-height: 30px;
						border: 1px solid #ffffff;
						border-radius: 6px;
						font-size: 14px;
						color: #fff;
					}

					.invite-icon {
						display: block;
						width: 136px;
						height: 35px;
						margin-left: 10px;
					}
				}

				.qs-user-menu-list {
					width: 140px;
					background: rgba(0, 0, 0, 0.7);
					box-shadow: 0px 0px 5px 1px rgba(220, 220, 220, 0.35);
					border-radius: 6px;
					padding: 30px 0;
					height: 380px;

					.text {
						width: 84px;
						margin: 0 auto;
						font-size: 14px;
						color: #fefefe;
						line-height: 40px;
					}
				}
			}
		}

		::v-deep .el-dialog {
			display: flex;
			flex-direction: column;
			margin: 0 !important;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			// 内容最大可见度
			max-height: 90vh;
		}

		::v-deep .el-dialog .el-dialog__body {
			max-height: 85% !important;
			min-height: 70%;
			overflow-y: auto;
			flex: 1;
		}
	}
</style>
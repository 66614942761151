/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-02-27 09:04:55
 * @LastEditors: pj
 * @LastEditTime: 2023-04-27 13:50:25
 */
import {
    login,
    loginOut,
    getAccountInfo
} from "@/api/user/index";
import {
    setToken,
    getToken,
    removeToken,
    removeUserInfo,
    getUserInfo
} from "@/utils/auth";
import md5 from "js-md5";
const state = {
    name: '',
    token: getToken(),
    userInfo: JSON.parse(getUserInfo()),
    wechat_bind: 0,
    company_id: 0
}
const mutations = {
    // 设置用户名
    SET_USERNAME: (state, username) => {
        state.name = username
    },
    // 设置token
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    // 设置用户信息
    SET_USERINFO: (state, userInfo) => {
        state.userInfo = JSON.stringify(userInfo)
    },
    //设置用户绑定微信状态
    SET_WECHAT_BIND: (state, data) => {
        state.wechat_bind = data
    },
}

const actions = {
    // 登录
    login ({
        commit
    }, userInfo) {
        return new Promise((resolve, reject) => {
            var params = {
                ...userInfo
            }
            params.password = md5(userInfo.password)
            login(params).then((result) => {
                if (result.code == '0000') {
                    setToken(result.data.token)
                    commit('SET_TOKEN', result.data.token)
                }
                resolve(result)
            }).catch((err) => {
                // console.log(err)
                reject()
            });
        })
    },
    // 退出登录
    loginOut ({
        commit
    }) {
        return new Promise((resolve, reject) => {
            loginOut().then(res => {
                removeToken()
                removeUserInfo()
                commit('SET_TOKEN', '')
                resolve(res)
            }).catch((err) => {
                // console.log(err)
                reject()
            });
        })
    },
    // 获取用户信息
    initUserInfo ({
        commit
    }) {
        return new Promise((resolve, reject) => {
            getAccountInfo().then(res => {
                if (res.code == '0000') {
                    commit('SET_WECHAT_BIND', res.data.wechat_bind)
                }
                resolve(res)
            }).catch((err) => {
                // console.log(err)
                reject()
            });
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}

import Cookies from 'js-cookie'

const TokenKey = 'qs_ats_token'
const SelectCompanyStateKey = 'qs_ats_is_select_company'
const UserInfoKey = 'qs_ats_user'
// 缓存token信息
export function getToken () {
    return Cookies.get(TokenKey)
}

export function setToken (token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken () {
    return Cookies.remove(TokenKey)
}

// 缓存是否出现选择企业弹窗
export function setSelectCompanyStateId (isSelect) {
    return localStorage.setItem(SelectCompanyStateKey, isSelect)
}
export function getSelectCompanyStateId () {
    return localStorage.getItem(SelectCompanyStateKey)
}
export function removeSelectCompanyStateId () {
    return localStorage.removeItem(SelectCompanyStateKey)
}

// 缓存用户信息
export function setUserInfo (userInfo) {
    return localStorage.setItem(UserInfoKey, userInfo)
}
export function getUserInfo () {
    return localStorage.getItem(UserInfoKey)
}
export function removeUserInfo () {
    return localStorage.removeItem(UserInfoKey)
}

// 缓存方法
export function setRequestData (key, val) {
    return localStorage.setItem(key, val)
}
export function getRequestData (key) {
    return localStorage.getItem(key)
}
export function removeRequestData (key) {
    return localStorage.removeItem(key)
}
<template>
    <el-dialog :close-on-click-modal="false" title="" :visible.sync="inviteVisible" center width="540px" :before-close="handleClose" append-to-body>
        <div slot="title">
            <div class="qs-invite-link-title">邀请成员</div>
        </div>
        <div class="qs-dialog-content">
            <div class="qs-invite-box">
                <span class="qs-invite-link">{{ form.invite_link }}</span>
                <el-button class="qs-invite-btn qs-btn-linear-gradient" @click="_copy(form.invite_link)">复制链接</el-button>
            </div>
            <div class="qs-invite-desc">
                链接有效期：
                <span class="text">{{ form.valid_date }}天</span>
                到期后链接将失效无法访问 ，用户通过此链接 邀请注册
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            inviteVisible: false,
            form: {
                invite_link: '',
                valid_date: 0,
            },
        }
    },
    mounted() {},
    methods: {
        // 打开弹窗
        handleOpen(data) {
            this.form = data
            this.inviteVisible = true
        },
        // 关闭页面
        handleClose() {
            this.inviteVisible = false
        },
        _copy(context) {
            // 创建输入框元素
            let oInput = document.createElement('input')
            // 将想要复制的值
            oInput.value = context
            // 页面底部追加输入框
            document.body.appendChild(oInput)
            // 选中输入框
            oInput.select()
            // 执行浏览器复制命令
            document.execCommand('Copy')
            // 弹出复制成功信息
            this.$message.success('复制成功')
            // 复制后移除输入框
            oInput.remove()
        },
    },
}
</script>

<style lang="scss" scoped>
.qs-invite-link-title {
    text-align: left;
    color: #333;
    font-size: 18px;
    font-weight: bold;
    box-sizing: border-box;
    padding-left: 6px;
}
.qs-dialog-content {
    width: 100%;
    .qs-invite-box {
        width: 478px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;
        .qs-invite-link {
            color: #333333;
            width: 360px;
            background: #ffffff;
            border: 1px solid #e3eef6;
            border-radius: 6px;
            padding: 12px;
            margin-right: 18px;
            font-size: 14px;
        }
        .qs-invite-btn {
            color: #fff;
        }
    }
    .qs-invite-desc {
        width: 478px;
        height: 80px;
        background: #f9fafb;
        border: 1px solid #e3eef6;
        border-radius: 6px;
        padding: 19px 17px;
        font-size: 14px;
        .text {
            color: #6874f2;
        }
    }
}

.qs-btns-wrapper {
    text-align: center;
    .el-button + .el-button {
        margin-left: 35px;
    }
    .qs-btn-confirm {
        color: #fff;
        font-size: 14px;
        border: none;
    }
    .qs-btn-cancel {
        background: #f4f8ff;
        color: #666666;
        font-size: 14px;
        border: none;
    }
}
</style>
